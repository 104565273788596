const SERVER_URL = `${process.env.REACT_APP_SERVER_URL}`;

const nullAgent = { pk: -1, name: '' };
const serviceTypes = ['Phone Service', 'Commissary', 'Booking'];
const ROWS_PER_PAGE = [10, 25, 50]
const MAX_PRINT_FETCH = 2000

const TZS = [
    "Hawaii Standard Time (UTC -10)",
    "Alaska Standard Time (UTC -9)",
     "Pacific Standard Time (UTC -8)",
    "Mountain Standard Time (UTC -7)",
    "Central Standard Time (UTC -6)",
    "Eastern Standard Time (UTC -5)",
]


export { SERVER_URL, nullAgent, serviceTypes, MAX_PRINT_FETCH, ROWS_PER_PAGE, TZS };

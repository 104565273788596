import {
  Box,
  Button,
  Container,
  makeStyles,
  Paper,
  Snackbar,
  TextField,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { SERVER_URL } from '../global';
import CircularProgress from '@material-ui/core/CircularProgress';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Avatar from '@material-ui/core/Avatar';
import theme from '../theme';

const useStyles = makeStyles({
  root: {
    marginTop: '170px',
    display: 'flex',
    flexDirection: 'column',
    'max-width': '360px',
  },
  inputsRoot: {
    display: 'flex',
    'flex-direction': 'column',
    '& .MuiButtonBase-root': {
      marginTop: '30px',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    alignSelf: 'center',
    marginTop: '30px',
    marginBottom: '-20px',
  },
  text: {
    textAlign: 'center',
    padding: '30px',
  },
  flexCenter: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: '20px 0px',
  },
},{ index: 1 });

const EnterEmail = ({ history }: any) => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSnackbar] = useState<
    'success' | 'info' | 'warning' | 'error' | undefined
  >('success');
  const emailIsValid = () => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.toLowerCase());
  };
  const sendToEmail = async () => {
    setLoading(true);
    if (emailIsValid()) {
      await fetch(SERVER_URL + 'api/password_reset/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
        }),
      });
      setResetSuccess(true);
      setTimeout(() => history.push('/'), 2000);
    } else {
      setLoading(false);

      setEmailError(true);
    }
  };
  if (resetSuccess) {
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={Boolean(showSnackbar)}
        autoHideDuration={8000}
      >
        <Alert severity={showSnackbar}>
          {showSnackbar === 'success'
            ? 'Email was sent!'
            : 'Something went wrong, try again later'}
        </Alert>
      </Snackbar>
    );
  }
  return (
    <div id="table">
      <ThemeProvider theme={theme}>
        <Container className={classes.root}>
          <Paper elevation={3} className={classes.paper}>
            <Avatar className={classes.avatar}>
              <VpnKeyIcon />
            </Avatar>
            <Typography variant="h5" className={classes.text}>
              Password Reset
            </Typography>
            <Typography className={classes.text} style={{ paddingTop: '0px' }}>
              Please enter your email and if this email exists in system -
              you&#39;ll get reset link
            </Typography>
            <Container className={classes.inputsRoot}>
              <TextField
                value={email}
                onChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  setEmail(e.target.value);
                }}
                error={emailError}
                label="Email"
                helperText={emailError && 'Email in incorrect'}
                variant="outlined"
              >
                Email
              </TextField>

              {!loading ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={sendToEmail}
                >
                  Submit
                </Button>
              ) : (
                  <Container className={classes.flexCenter}>
                    <CircularProgress />
                  </Container>
                )}
              <Box textAlign="center" margin="16px">
                <Link to="/" className="link">
                  Sign in
                </Link>
              </Box>
            </Container>
          </Paper>
        </Container>
      </ThemeProvider>
    </div>
  );
};

export default EnterEmail;

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { nullAgent } from '../../global';
import { Agent, Kiosk } from './types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { serviceTypes } from '../../global';
import { alphabeticSort, getAlertType, representationSort } from './utils';

type LocProps = {
  classes: any;
  facility: Agent;
  setKiosk: Function;
  setFacility: Function;
  facilities: Agent[];
  kiosks: Kiosk[];
  kiosk: Kiosk;
  handleInstanceChange: Function;
};

const handleInstanceChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  setInstance: Function,
  array: any[]
) => {
  const currInstance = array.find(
    (obj: any) => String(obj.pk) === String(event.target.value)
  );
  if (currInstance) {
    setInstance(currInstance!);
  } else {
    setInstance(nullAgent);
  }
};

const LocationAccordion = ({
  classes,
  facility,
  setKiosk,
  setFacility,
  facilities,
  kiosks,
  kiosk,
}: LocProps) => {
  return (
    <Accordion classes={{ root: classes.accRoot }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Location</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Container className={classes.datesContainer}>
          <TextField
            id="select-location"
            select
            label="Facility"
            value={facility.pk}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setKiosk(nullAgent);
              handleInstanceChange(event, setFacility, facilities);
            }}
            className={classes.select}
          >
            <MenuItem value={-1}>
              <em>None</em>
            </MenuItem>
            {facility.pk > -1 &&
              <MenuItem value={facility.pk}>
              <em>{facility.name}</em>
              </MenuItem>
            }
            {facilities.map((option) => {
              if (option.pk !== facility.pk) {
                return <MenuItem key={option.pk} value={option.pk}>
                  {option.name}
                </MenuItem>
              }
            })}
          </TextField>
          {facility.pk > -1 && (
            <TextField
              id="select-kiosk"
              select
              label="Kiosk"
              value={kiosk.pk}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleInstanceChange(event, setKiosk, kiosks)
              }
              className={classes.select}
              margin="normal"
            >
              <MenuItem value={-1}>
                <em>None</em>
              </MenuItem>
              {kiosk.pk !== -1 &&
                <MenuItem value={kiosk.pk}>
                  <em>{kiosk.name}</em>
                </MenuItem>
              }
              {kiosks.map((option) => {
                if (option.pk !== kiosk.pk) {
                  return <MenuItem key={option.pk} value={option.pk}>
                    {option.name}
                  </MenuItem>
                }
              })}

            </TextField>
          )}
        </Container>
      </AccordionDetails>
    </Accordion>
  );
};

type AgentProps = {
  classes: any;
  agent: Agent;
  agents: Agent[];
  setAgent: Function;
};

const AgentAccordion = ({ classes, agent, setAgent, agents }: AgentProps) => {
  return (
    <Accordion classes={{ root: classes.accRoot }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Agent</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TextField
          id="select-agent"
          select
          label="Agent"
          value={agent.pk}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleInstanceChange(event, setAgent, agents)
          }
          className={classes.select}
        >
          <MenuItem value={-1}>
            <em>None</em>
          </MenuItem>
          {agent.pk > -1 &&
            <MenuItem value={agent.pk}>
              <em>{agent.name}</em>
            </MenuItem>
          }
          {agents.map((option) => {
            if (option.pk !== agent.pk) {
              return <MenuItem key={option.pk} value={option.pk}>
                {option.name}
              </MenuItem>
            }
          })}
        </TextField>
      </AccordionDetails>
    </Accordion>
  );
};

type SuccessfulProps = {
  classes: any;
  successful: 'all' | 'successful' | 'failed';
  setSuccessful: Function;
};

const SuccessfulAccordion = ({ classes, successful, setSuccessful }: SuccessfulProps) => {
  return (
    <Accordion classes={{ root: classes.accRoot }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Result</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TextField
          id="select-successful"
          select
          value={successful}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setSuccessful(event.target.value)
          }
          className={classes.select}
        >
          <MenuItem value={'all'}>
            <em>None</em>
          </MenuItem>
          <MenuItem value={'failed'}>
            <em>Only Failed</em>
          </MenuItem>
          <MenuItem value={'successful'}>
            <em>Only Successful</em>
          </MenuItem>
        </TextField>
      </AccordionDetails>
    </Accordion>
  );
};

type DateRangeProps = {
  classes: any;
  startDate: Date | null;
  endDate: Date | null;
  handleStartDateChange: (
    date: Date | null,
    value?: string | null | undefined
  ) => void;
  handleEndDateChange: (
    date: Date | null,
    value?: string | null | undefined
  ) => void;
};

const timeToZero = (date: Date | null) => {
  if (date) {
    date.setHours(0, 0, 0)
  }
  return date
}
const timeToMax = (date: Date | null) => {
  if (date) {
    date.setHours(23, 59, 59)
  }
  return date
}

const DateRangeAccordion = ({
  classes,
  startDate,
  endDate,
  handleEndDateChange,
  handleStartDateChange,
}: DateRangeProps) => {
  return (
    <Accordion classes={{ root: classes.accRoot }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Date range</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Container className={classes.datesContainer}>
            <KeyboardDatePicker
              autoOk
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-start"
              label="Start Date"
              value={startDate}
              onChange={(date) => handleStartDateChange(date)}
              maxDate={endDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            {startDate && (
              <KeyboardDatePicker
                autoOk
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-end"
                label="End Date"
                value={endDate}
                onChange={(date) => handleEndDateChange(date)}
                minDate={startDate}
                initialFocusedDate={startDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            )}
          </Container>
        </MuiPickersUtilsProvider>
      </AccordionDetails>
    </Accordion>
  );
};

type ServiceTypeProps = {
  classes: any;
  serviceType: number;
  setServiceType: Function;
};

const getServiceIndex: any = {
  'Phone Service': 0,
  'Commissary': 1,
  'Booking': 2,
}

const ServiceTypeAccordion = ({
  classes,
  serviceType,
  setServiceType,
}: ServiceTypeProps) => {
  return (
    <Accordion classes={{ root: classes.accRoot }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Service Type</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TextField
          id="select-service-type"
          select
          label="Service Type"
          value={serviceType}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setServiceType(Number(event.target.value))
          }
          className={classes.select}
        >
          <MenuItem value={-1}>
            <em>None</em>
          </MenuItem>
          {[...serviceTypes].sort(alphabeticSort).map((option) => (
            <MenuItem key={getServiceIndex[option]} value={getServiceIndex[option]}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </AccordionDetails>
    </Accordion>
  );
};

type AlertTypeProps = {
  classes: any;
  alertType: number;
  setAlertType: Function;
  types: number[];
};

const AlertTypeAccordion = ({
  classes,
  alertType,
  setAlertType,
  types,
}: AlertTypeProps) => {
  return (
    <Accordion classes={{ root: classes.accRoot }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Alert Type</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TextField
          id="select-service-type"
          select
          label="Alert Type"
          value={alertType}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setAlertType(event.target.value)
          }
          className={classes.select}
        >
          <MenuItem value={-1}>
            <em>None</em>
          </MenuItem>
          {alertType > -1 &&
            <MenuItem value={alertType}>
              <em>{getAlertType(alertType)}</em>
            </MenuItem>
          }
          {types.sort((a,b)=>representationSort(a,b,getAlertType)).map((option) => {
            if (option !== alertType) {
              return <MenuItem key={option} value={option}>
                {getAlertType(option)}
              </MenuItem>
            }
          })}

        </TextField>
      </AccordionDetails>
    </Accordion>
  );
};

export {
  LocationAccordion,
  AgentAccordion,
  DateRangeAccordion,
  ServiceTypeAccordion,
  AlertTypeAccordion,
  SuccessfulAccordion
};
  function a(a: any, b: any): ((a: string, b: string) => number) | undefined {
    throw new Error('Function not implemented.');
  }


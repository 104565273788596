import React, { Component } from 'react';
import './App.scss';

import { Transactions } from './pages/transactions';
import { Collections } from './pages/collections';
import { AlertHistory } from './pages/alertHistory';
import { CashLevel } from './pages/cashLevel';
import Profile from './pages/profile';
import ChangePassword from './pages/changePassword';

import store from './redux/store';
import { setProfile } from './redux/profile/profile.action';

import { SERVER_URL } from './global';
import Login from './components/login/login.component';
import ResetPassword from './pages/resetPassword';
import SideBar from './components/sidebar/sidebar.component';
import Logout from './pages/logout';
import {
  withRouter,
  Route,
  Switch,
  Redirect,
  RouteComponentProps,
} from 'react-router-dom';
import { Profile as ProfileType, StoreState } from './redux/types';
import { connect } from 'react-redux';
import EnterEmail from './pages/enterEmail';
import { Box, CircularProgress } from '@material-ui/core';
import Error404 from './pages/404';

type Props = {
  profile?: ProfileType;
} & RouteComponentProps;
type State = {
  loggedIn: boolean;
  username: string;
  showSnackbar: 'success' | 'info' | 'warning' | 'error' | undefined;
  loading: boolean;
};

class App extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loggedIn: localStorage.getItem('token') ? true : false,
      username: '',
      showSnackbar: undefined,
      loading: true,
    };
  }

  admins = ['auditadmin', 'superuser'];
  auditAdmins = ['auditadmin', 'superuser', 'facilityadmin'];
  componentDidMount = () => {
    this._getProfileInfo();
  };

  _getProfileInfo = () => {
    this.setState({ loading: true });
    fetch(SERVER_URL + 'profile/me/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${localStorage.getItem('token')}`,
      },
    })
      .then((result) => result.json())
      .then((data) => {
        if (data.detail) {
          switch (data.detail) {
            default:
              this.setState({ loading: false });
              this.setState({ loggedIn: false });
          }
        } else {
          store.dispatch(setProfile(data));
          this.setState({ loggedIn: true });
          this.setState({ loading: false });
        }
      })
      .catch(() => {
        this.setState({ loggedIn: false });
        this.setState({ loading: false });
      });
  };

  onLogin = async (token: string) => {
    localStorage.setItem('token', token);
    this._getProfileInfo();
  };

  onLogout = () => {
    localStorage.removeItem('token');
    this.setState({ loggedIn: false });
    this.props.history.push('/');
  };

  setShowSnackBar = (
    name: 'success' | 'info' | 'warning' | 'error' | undefined
  ) => {
    this.setState({ showSnackbar: name });
  };

  render() {
    const { loggedIn, showSnackbar, loading } = this.state;
    const { profile } = this.props;
    if (loading) {
      return (
        <Box className="centerContainer">
          <CircularProgress />
        </Box>
      );
    }
    return (
      <div className="App">
        <SideBar loggedIn={loggedIn} onLogout={this.onLogout} />
        {loggedIn ? (
          <Switch>
            <Route
              exact
              path="/"
              component={() => (
                <Redirect
                  to={
                    profile!.role === 'agent'
                      ? '/collections/'
                      : '/transactions/'
                  }
                />
              )}
            />
            <Route
              path="/log-out/"
              component={() => <Logout onLogout={this.onLogout} />}
            />
            <Route
              path="/profile/"
              component={() => (
                <Profile
                  profileRedux={profile}
                  showSnackbar={showSnackbar}
                  setShowSnackbar={this.setShowSnackBar}
                  onLogout={this.onLogout}
                />
              )}
            />
            <Route
              path="/change-password/"
              component={() => (
                <ChangePassword
                  // showSnackbar={showSnackbar}
                  // setShowSnackbar={this.setShowSnackBar}
                  onLogout={this.onLogout}
                />
              )}
            />
            <Route path="/collections/" component={Collections} />
            {profile?.role && this.auditAdmins.includes(profile.role) && (
              <Route path="/transactions/" component={Transactions} />
            )}
            {/* {profile?.role && this.admins.includes(profile.role) && ( */}
            {/* <> */}
            <Route path="/alert-history/" component={AlertHistory} />
            <Route path="/cash-level/" component={CashLevel} />
            {/* </> */}
            {/* )} */}
            <Route component={Error404} />{' '}
          </Switch>
        ) : (
            <Switch>
              <Route
                exact
                path="/"
                component={() => <Login onLogin={this.onLogin} />}
              />
              <Route
                path="/reset-password/:token"
                render={(props: any) => (
                  <ResetPassword {...props} onLogout={this.onLogout} />
                )}
              />
              <Route
                path="/reset-password/"
                render={(props: any) => <EnterEmail {...props} />}
              />
              <Route render={() => <Redirect to="/" />} />
            </Switch>
          )}
      </div>
    );
  }
}

const mapStateToProps = (store: StoreState) => ({
  profile: store.profile,
});

export default connect(mapStateToProps)(withRouter(App));

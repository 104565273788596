import { captureMessage } from '@sentry/react';
import { SERVER_URL } from '../../global';

export const downloadExportFile = async (name: string, filtersURL: string, order: string) => {
  const downloadBlob = ({
    blob,
    fileName,
    replaceDefaultName = false,
  }: {
    blob: any;
    fileName: string;
      replaceDefaultName?: boolean;
  }) => {
    const url: any = window.URL.createObjectURL(new Blob([blob]));
    const link: any = document.createElement('a');
    const today: any = new Date();
    const name: string = replaceDefaultName
      ? fileName
      : fileName.toLowerCase().replace(' ', '-');
    // make the link downloadable
    link.href = url;
    link.setAttribute(
      'download',
      `${name}-export-${today.toLocaleDateString()}.csv`
    );
    document.body.appendChild(link);
    link.click();
  };

  const response = await fetch(`${SERVER_URL}csv/${order}/?type=${name}${filtersURL}`, {
    headers: {
      Authorization: `JWT ${localStorage.getItem('token')}`,
    },
  });
  if (response.status === 200) {
    const content = await response.text();
    downloadBlob({ blob: content, fileName: name });
  } else {
    if (response.status !== 204) {
      captureMessage(await response.text());
    }
  }
};

import { Box, Chip, makeStyles, Theme, Toolbar } from '@material-ui/core';
import React from 'react';
import { FilterChip } from './types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiChip-root': {
      margin: theme.spacing(0.5),
    },
  },
}),{ index: 1 }
);

type Props = {
  FilterChips: FilterChip[];
  setShowFilters: Function;
};

const FiltersToolbar = ({ FilterChips, setShowFilters }: Props) => {
  const classes = useStyles();
  return (
    <Toolbar>
      <Box className={classes.root} display="flex" flexWrap="wrap">
        {FilterChips.map(
          (item) =>
            item.applied && (
              <Chip
                icon={item.icon ? React.createElement(item.icon!) : undefined}
                key={item.label}
                label={item.label}
                onClick={() => setShowFilters(true)}
                onDelete={item.onClose}
              />
            )
        )}
      </Box>
    </Toolbar>
  );
};

export default FiltersToolbar;

import {
  Box,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import SyncIcon from '@material-ui/icons/Sync';
import FilterListIcon from '@material-ui/icons/FilterList';
import React from 'react';

type Props = {
  header: string;
  classes: any;
  onRefresh: (event: any) => void;
  setShowFilters: Function;
  showFilters: boolean;
  closeDetails: Function;
};
export default function CustomToolbar({
  header,
  classes,
  onRefresh,
  setShowFilters,
  showFilters,
  closeDetails,
}: Props) {
  return (
    <Toolbar
      style={{ justifyContent: 'space-between', paddingLeft: '16px' }}
      className={classes.root}
    >
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {header}
      </Typography>
      <Box>
        <Tooltip title="Refresh list">
          <IconButton aria-label="refresh list" onClick={onRefresh}>
            <SyncIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Filter list">
          <IconButton
            aria-label="filter list"
            onClick={() => {
              closeDetails();
              setShowFilters(!showFilters);
            }}
          >
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      </Box>{' '}
    </Toolbar>
  );
}

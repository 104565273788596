import theme from '../../../theme';
import React, { useState } from 'react';
import { TransactionData as Data } from '../types';
import {
  Box,
  Container,
  IconButton,
  Tooltip,
  Typography,
  CardMedia,
  Modal,
  Grid,
} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import {
  getPSLoadType,
  getServiceType,
  getPaymentType,
  moneyFormatter,
  formatPhoneNumber,
  booleanConverter,
} from '../utils';
import { useStylesDetails as useStyles } from '../styles';

type DetailsProps = {
  data: Data;
  closeDetails: Function;
};

export const Details = ({ data, closeDetails }: DetailsProps) => {
  const classes = useStyles(theme);
  const [openSummary, setOpenSummary] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const ModalBody = (
    <div id="modal">
      <Grid container direction="row" justify="space-between" alignItems="center">
        <img src={data.photo} alt="" />
        <IconButton autoFocus aria-label="close" onClick={handleCloseModal} className={classes.customizedButton}>
          <CloseIcon />
        </IconButton>
      </Grid>
    </div>
  );

  const howOften = (array: number[], el: number) =>
    array.filter((x) => x === el).length;
  return (
    <Box className={classes.root}>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
      >
        {ModalBody}
      </Modal>
      <Tooltip title="Close">
        <IconButton
          className={classes.crossIcon}
          onClick={() => closeDetails()}
          aria-label="close details"
        >
          <CloseIcon />
        </IconButton>
      </Tooltip>
      <Container className={classes.textContainer}>
        <Typography className={classes.header}>Transaction ID</Typography>
        <Typography className={classes.headerValue}>{data.pk}</Typography>
      </Container>
      <Accordion
        expanded={openSummary}
        onChange={() => {
          setOpenSummary(!openSummary);
        }}
        classes={{ root: classes.accRoot }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.header}>Summary</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accSummary}>
          <Container className={classes.sTextContainer}>
            <Typography className={classes.header}>Kiosk</Typography>
            <Typography className={classes.headerValue}>
              {data.kiosk__name}
            </Typography>
          </Container>
          {/* <Container className={classes.sTextContainer}>
        <Typography className={classes.header}>Collection</Typography>
        <Typography className={classes.headerValue}>
          {data.collection__pk}
        </Typography>
      </Container> */}
          <Container className={classes.sTextContainer}>
            <Typography className={classes.header}>
              Date of transaction
            </Typography>
            <Typography className={classes.headerValue}>
              {data.created_on}
            </Typography>
          </Container>
          <Container className={classes.sTextContainer}>
            <Typography className={classes.header}>Service Type</Typography>
            <Typography className={classes.headerValue}>
              {getServiceType(data.service_type)}
            </Typography>
          </Container>
          {data.ps_load_type !== undefined && data.service_type === 0 && (
            <Container className={classes.sTextContainer}>
              <Typography className={classes.header}>Load Type</Typography>
              <Typography className={classes.headerValue}>
                {getPSLoadType(data.ps_load_type)}
              </Typography>
            </Container>
          )}
          <Container className={classes.sTextContainer}>
            <Typography className={classes.header}>Phone#</Typography>
            <Typography className={classes.headerValue}>
              {formatPhoneNumber(data.phone)}
            </Typography>
          </Container>
          <Container className={classes.sTextContainer}>
            <Typography className={classes.header}>Payment Type</Typography>
            <Typography className={classes.headerValue}>
              {getPaymentType(data.payment_type)}
            </Typography>
          </Container>

          {data.card_number && (
            <Container className={classes.sTextContainer}>
              <Typography className={classes.header}>Card Last 4</Typography>
              <Typography className={classes.headerValue}>
                {data.card_number}
              </Typography>
            </Container>
          )}
        </AccordionDetails>
      </Accordion>
      <Container className={classes.aTextContainer}>
        {data.payment_type === 1 && (
          <Accordion classes={{ root: classes.accRoot }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.header}>Denominations</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classes.header}>Bills</Typography>
            </AccordionDetails>
            
            <AccordionDetails>
              <Container className={classes.sideTextContainer}>
                <Typography className={classes.header}>$1</Typography>
                <Typography className={classes.headerValue}>
                  {howOften(data.bills, 1)}
                </Typography>

                <Typography className={(classes.header, classes.sHeader)}>
                  $5
                </Typography>
                <Typography className={classes.headerValue}>
                  {howOften(data.bills, 5)}
                </Typography>

                <Typography className={(classes.header, classes.sHeader)}>
                  $20
                </Typography>
                <Typography className={classes.headerValue}>
                  {howOften(data.bills, 20)}
                </Typography>

                <Typography className={(classes.header, classes.sHeader)}>
                  $100
                </Typography>
                <Typography className={classes.headerValue}>
                  {howOften(data.bills, 100)}
                </Typography>
              </Container>
              <Container className={classes.sideTextContainer}>
                <Typography className={classes.header}>$2</Typography>
                <Typography className={classes.headerValue}>
                  {howOften(data.bills, 2)}
                </Typography>

                <Typography className={(classes.header, classes.sHeader)}>
                  $10
                </Typography>
                <Typography className={classes.headerValue}>
                  {howOften(data.bills, 10)}
                </Typography>

                <Typography className={(classes.header, classes.sHeader)}>
                  $50
                </Typography>
                <Typography className={classes.headerValue}>
                  {howOften(data.bills, 50)}
                </Typography>

              </Container>
            </AccordionDetails>

            <AccordionDetails>
              <Typography className={classes.header}>Coins</Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Container className={classes.sideTextContainer}>
                <Typography className={classes.header}>$0.01</Typography>
                <Typography className={classes.headerValue}>
                  {howOften(data.coin, .01)}
                </Typography>

                <Typography className={(classes.header, classes.sHeader)}>
                  $0.10
                </Typography>
                <Typography className={classes.headerValue}>
                  {howOften(data.coin, .1)}
                </Typography>

                <Typography className={(classes.header, classes.sHeader)}>
                  $0.50
                </Typography>
                <Typography className={classes.headerValue}>
                  {howOften(data.coin, .5)}
                </Typography>
              </Container>
              <Container className={classes.sideTextContainer}>
                <Typography className={classes.header}>$0.05</Typography>
                <Typography className={classes.headerValue}>
                  {howOften(data.coin, .05)}
                </Typography>

                <Typography className={(classes.header, classes.sHeader)}>
                  $0.25
                </Typography>
                <Typography className={classes.headerValue}>
                  {howOften(data.coin, .25)}
                </Typography>

                <Typography className={(classes.header, classes.sHeader)}>
                  $1
                </Typography>
                <Typography className={classes.headerValue}>
                  {howOften(data.coin, 1)}
                </Typography>
              </Container>
            </AccordionDetails>
          </Accordion>
        )}
        <Accordion classes={{ root: classes.accRoot }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.header}>Depositor</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Container className={classes.sideTextContainer}>
              <Typography className={(classes.header, classes.sHeader)}>
                First Name
              </Typography>
              <Typography className={classes.headerValue}>
                {data.inmate_first_name}
              </Typography>
              <Typography className={(classes.header, classes.sHeader)}>
                PIN
              </Typography>
              <Typography className={classes.headerValue}>
                {data.pin}
              </Typography>
            </Container>
            <Container className={classes.sideTextContainer}>
              <Typography className={(classes.header, classes.sHeader)}>
                Last Name
              </Typography>
              <Typography className={classes.headerValue}>
                {data.inmate_last_name}
              </Typography>

              <Typography className={(classes.header, classes.sHeader)}>
                ID
              </Typography>
              <Typography className={classes.headerValue}>
                {data.inmate_id}
              </Typography>
            </Container>
          </AccordionDetails>
          {data.photo && (
            <Box padding={2}>
              <CardMedia onClick={handleOpenModal} className={classes.media} image={data.photo} title='' />
            </Box>
          )}
        </Accordion>
        <Accordion classes={{ root: classes.accRoot }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.header}>Result</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Container className={classes.sideTextContainer}>
              <Typography className={(classes.header, classes.sHeader)}>
                {data.successful?"Successful":"Failed"}
              </Typography>
              <Typography className={classes.headerValue}>
                {booleanConverter(data.successful)}
              </Typography>
              <Typography className={(classes.header, classes.sHeader)}>
                Details
              </Typography>
              <Typography className={classes.headerValue}>
                {data.result_description}
              </Typography>
            </Container>
          </AccordionDetails>
        </Accordion>
        <Accordion classes={{ root: classes.accRoot }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.header}>Amounts</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Container className={classes.sideTextContainer}>
              <Typography className={(classes.header, classes.sHeader)}>
                Service Fee
              </Typography>
              <Typography className={classes.headerValue}>
                {data.service_fee ? moneyFormatter(data.service_fee) : '$0.00'}
              </Typography>
              <Typography className={(classes.header, classes.sHeader)}>
                Processing Fee
              </Typography>
              <Typography className={classes.headerValue}>
                {data.processing_fee
                  ? moneyFormatter(data.processing_fee)
                  : '$0.00'}
              </Typography>
              <Typography className={(classes.header, classes.sHeader)}>
                Subtotal
              </Typography>
              <Typography className={classes.headerValue}>
                {moneyFormatter(data.subtotal)}
              </Typography>
              <Typography className={(classes.header, classes.sHeader)}>
                Total
              </Typography>
              <Typography className={classes.headerValue}>
                {moneyFormatter(data.total)}
              </Typography>
            </Container>
          </AccordionDetails>
        </Accordion>
      </Container>
    </Box>
  );
};

import { createStore, applyMiddleware, compose } from 'redux';

// eslint-disable-next-line
import { logger } from "redux-logger";

import * as Sentry from '@sentry/react';

import rootReducer from './root-reducer';
import { cloneDeep } from 'lodash';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
});


const middlewares = [
  // logger,
]; // logger,

const store = createStore(rootReducer, compose(applyMiddleware(...middlewares), sentryReduxEnhancer));

export default store;

import React, { useEffect, useState } from 'react';
import ChangePassword from './changePassword';
import { SERVER_URL } from '../global';
import Loader from 'react-loader-spinner';

const ResetPassword = (props: any) => {
  const token = props.match.params.token;
  const { onLogout, toEmail } = props;
  const [tokenCorrect, setTokenCorrect] = useState(false);
  const [loading, setLoading] = useState(true);

  const checkToken = async () => {
    const response = await fetch(
      SERVER_URL + 'api/password_reset/validate_token/',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: token,
        }),
      }
    );
    if (response.status === 200) {
      setLoading(false);
      setTokenCorrect(true);
    } else {
      setLoading(false);
      setTokenCorrect(false);
    }
  };
  useEffect(() => {
    if (!toEmail) {
      checkToken();
    }
    // eslint-disable-next-line
  }, []);
  if (loading) {
    return (
      <div id="table">
        <Loader color="#25408F" type='Circles' />
      </div>
    );
  }
  if (tokenCorrect) {
    return <ChangePassword onLogout={onLogout} reset token={token} />;
  } else {
    return <div id="table">404: page not found</div>;
  }
};

export default ResetPassword;

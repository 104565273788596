import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MenuIcon from '@material-ui/icons/Menu';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import InfoIcon from '@material-ui/icons/Info';
import AssessmentIcon from '@material-ui/icons/Assessment';
import FaceIcon from '@material-ui/icons/Face';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Theme from '../../theme';
import Logo from '../../img/logo.svg';
import { Container } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { StoreState } from '../../redux/types';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => {
  theme = Theme;
  return {
    root: {
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    listRoot: {
      color: theme.palette.secondary.contrastText,
      '& svg': {
        color: theme.palette.secondary.contrastText,
      },
    },
    listItem: {
      '& :hover': {
        color: theme.palette.primary.light,
      },
    },
    listItemIcon: {
      '& :hover': {
        color: theme.palette.secondary.contrastText,
      },
    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: theme.palette.secondary.main,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    icon: {
      color: 'green',
    },
    divider: {
      backgroundColor: theme.palette.grey[400],
    },
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    menuHeader: {
      marginTop: '15px',
    },
    dashboardHeader: {
      backgroundColor: theme.palette.grey[500],
      position: 'absolute',
      top: '110px',
      left: '0px',
      padding: '10px 70px',
    },
  };
},{ index: 1 });

type SideBarProps = {
  onLogout: Function;
  loggedIn: boolean;
  [name: string]: any;
};

const SideBar = ({ onLogout, loggedIn, ...props }: SideBarProps) => {
  const history = useHistory();
  const location = useLocation();
  const { window } = props;
  const classes = useStyles(Theme);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { role } = useSelector((store: StoreState) => store.profile!);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  let menuReports: any = {};
  switch (role) {
    case 'agent':
      menuReports = {
        Collections: InboxIcon,
      };
      break;
    case 'facilityadmin':
      menuReports = {
        Transactions: CompareArrowsIcon,
        Collections: InboxIcon,
      };
      break;
    case 'auditadmin':
    case 'superuser':
      menuReports = {
        Transactions: CompareArrowsIcon,
        Collections: InboxIcon,
        'Alert  History': InfoIcon,
        'Cash Level': AssessmentIcon,
      };
      break;
    default:
      break;
  }

  const convertToPageName = (rawPath: string) => {
    const result = rawPath
      .replace(/\//g, '')
      .replace(/-/g, ' ')
      .replace(/\b\w/g, function (c) {
        return c.toUpperCase();
      });
    return result;
  };

  const menuUser: any = {
    Profile: FaceIcon,
    'Log out': ExitToAppIcon,
  };

  const itemClick = (text: string) => {
    if (text === 'Log out') {
      onLogout();
    } else {
      history.push('/' + text.toLowerCase().replace(/\s+/g, '-') + '/');
    }
  };

  let header = '';
  if (!loggedIn) {
    header = 'Login';
  } else {
    header = convertToPageName(location.pathname);
    if (
      [
        '/collections/',
        '/transactions/',
        '/cash-history/',
        '/alert-history/',
        '/cash-level/',
      ].includes(location.pathname)
    ) {
      header = header + ' Report';
    }
  }

  const drawer = (
    <ThemeProvider theme={Theme}>
      <Container className={classes.listRoot}>
        <div className={classes.toolbar}>
          <img src={Logo} alt="" style={{ width: '150px', margin: '20px' }} />
        </div>
        {/* <Box className={classes.dashboardHeader}>
          <Typography variant="h6">Dashboard</Typography>
        </Box> */}
        {loggedIn && (
          <>
            <Typography
              className={classes.menuHeader}
              // style={{ marginTop: '80px' }}
              variant="h6"
            >
              Reports
            </Typography>
            <List>
              {Object.keys(menuReports).map((text: string) => (
                <ListItem
                  button
                  key={text}
                  classes={{ root: classes.listItem }}
                  onClick={() => {
                    history.push(
                      '/' + text.toLowerCase().replace(/\s+/g, '-') + '/'
                    );
                  }}
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    {React.createElement(menuReports[text])}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              ))}
            </List>
            <Divider className={classes.divider} />
            <Typography className={classes.menuHeader} variant="h6">
              User
            </Typography>
            <List>
              {Object.keys(menuUser).map((text: string) => (
                <ListItem
                  button
                  key={text}
                  classes={{ root: classes.listItem }}
                  onClick={() => {
                    itemClick(text);
                  }}
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    {React.createElement(menuUser[text])}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              ))}
            </List>
            <Divider className={classes.divider} />
          </>
        )}
      </Container>
    </ThemeProvider>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const username = useSelector((store: StoreState) => store.profile?.firstName);

  return (
    <ThemeProvider theme={Theme}>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <Container className={classes.headerContainer}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap>
                {header}
              </Typography>
            </Container>
            <Hidden xsDown>
              <Container>
                <Typography variant="h6" style={{ textAlign: 'right' }} noWrap>
                  {loggedIn ? 'Hi, ' + username : ''}
                </Typography>
              </Container>
            </Hidden>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={Theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              color="primary"
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
      </div>
    </ThemeProvider>
  );
};

SideBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default SideBar;

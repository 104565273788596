import theme from '../../../theme';
import React, { useState } from 'react';
import { AlertData as Data } from '../types';
import {
  Box,
  Container,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useStylesDetails as useStyles } from '../styles';
import { getAlertType } from '../utils';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

type DetailsProps = {
  data: Data;
  closeDetails: Function;
};

export const Details = ({ data, closeDetails }: DetailsProps) => {
  const classes = useStyles(theme);
  const [openSummary, setOpenSummary] = useState(true);
  return (
    <Box className={classes.root}>
      <Tooltip title="Close">
        <IconButton
          className={classes.crossIcon}
          onClick={() => closeDetails()}
          aria-label="close details"
        >
          <CloseIcon />
        </IconButton>
      </Tooltip>
      <Container className={classes.textContainer}>
        <Typography className={classes.header}>Alert ID</Typography>
        <Typography className={classes.headerValue}>{data.pk}</Typography>
      </Container>
      <Accordion
        expanded={openSummary}
        onChange={() => {
          setOpenSummary(!openSummary);
        }}
        classes={{ root: classes.accRoot }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.header}>Summary</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accSummary}>
          <Container className={classes.sTextContainer}>
            <Typography className={classes.header}>Kiosk</Typography>
            <Typography className={classes.headerValue}>
              {data.kiosk__name}
            </Typography>
          </Container>
          <Container className={classes.sTextContainer}>
            <Typography className={classes.header}>Facility</Typography>
            <Typography className={classes.headerValue}>
              {data.kiosk__location__name}
            </Typography>
          </Container>
          <Container className={classes.sTextContainer}>
            <Typography className={classes.header}>Date</Typography>
            <Typography className={classes.headerValue}>
              {data.created_on}
            </Typography>
          </Container>
          <Container className={classes.sTextContainer}>
            <Typography className={classes.header}>Type</Typography>
            <Typography className={classes.headerValue}>
              {getAlertType(data.alert_type)}
            </Typography>
          </Container>
          <Container className={classes.sTextContainer}>
            <Typography className={classes.header}>Description</Typography>
            <Typography
              className={classes.headerValue + ' ' + classes.description}
            >
              {data.description}
            </Typography>
          </Container>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

import React, { useState } from 'react';
import CustomTable from '../components/tables/table';
import { nullAgent, SERVER_URL } from '../global';
import { moneyFormatter } from '../components/tables/utils';
import { Details } from '../components/tables/collections/details';
import FiltersToolbar from '../components/tables/filtersToolbar';
import {
  CollectionData as Data,
  FetchProps,
  Location,
  CellType,
  HeadCell,
  FilterChip,
  Agent,
} from '../components/tables/types';
import { fetchWithAuth } from '../components/tables/utils';
import RemoveFromQueueIcon from '@material-ui/icons/RemoveFromQueue';
import DateRangeIcon from '@material-ui/icons/DateRange';
import BallotIcon from '@material-ui/icons/Ballot';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Filters from '../components/tables/collections/filters';

interface LocalCellType extends CellType {
  name: keyof Data;
}
interface LocalHeadCellType extends HeadCell {
  id: keyof Data;
}

export const Collections = () => {
  const [loading, setLoading] = useState(false);
  const [filterStartDate, setFilterStartDate] = useState<string | null>(null);
  const [filterEndDate, setFilterEndDate] = useState<string | null>(null);
  const [filterLocation, setFilterLocation] = useState<Location>({
    kiosk: nullAgent,
    facility: nullAgent,
  });
  const [filtersRefresh, setFiltersRefresh] = useState(false);
  const [filterAgent, setFilterAgent] = useState<Agent>(nullAgent);

  const FiltersWrapper = ({ setShowFilters }: any) => (
    <Filters
      filtersRefresh={filtersRefresh}
      filterEndDate={filterEndDate}
      filterLocation={filterLocation}
      filterStartDate={filterStartDate}
      filterAgent={filterAgent}
      setFilterEndDate={setFilterEndDate}
      setFilterLocation={setFilterLocation}
      setFilterAgent={setFilterAgent}
      setFilterStartDate={setFilterStartDate}
      setShowFilters={setShowFilters}
      setFiltersRefresh={setFiltersRefresh}
    />
  );

  const getURLFilters = () => {
    let URL = '';
    if (filterStartDate && filterEndDate) {
      URL += `&start_date=${filterStartDate}&end_date=${filterEndDate}`;
    }
    if (filterLocation.kiosk.pk !== -1) {
      URL += `&kiosk=${filterLocation.kiosk.pk}`;
    }
    if (filterLocation.facility.pk > -1) {
      URL += `&facility=${filterLocation.facility.pk}`;
    }
    if (filterAgent.pk > -1) {
      URL += `&agent=${filterAgent.pk}`;
    }
    return URL;
  };

  const fetchRows = async ({
    order,
    orderBy,
    page,
    setRows,
    rowsPerPage,
    setCount,
    setLoading,
    setError,
  }: FetchProps) => {
    setLoading(true);
    let URL =
      SERVER_URL +
      'collection/' +
      order +
      orderBy +
      '/' +
      `?page=${page + 1}&limit=${rowsPerPage}`;
    URL += getURLFilters();
    fetchWithAuth(URL, setRows, setCount, setLoading, setError);
  };

  const headCellsAll: LocalHeadCellType[] = [
    { id: 'collected_on', numeric: false, disablePadding: true, label: 'Date' },
    {
      id: 'kiosk__name',
      numeric: false,
      disablePadding: false,
      label: 'Kiosk',
    },
    {
      id: 'kiosk__location__name',
      numeric: false,
      disablePadding: false,
      label: 'Facility',
    },
    {
      id: 'agent__first_name',
      numeric: false,
      disablePadding: false,
      label: 'Agent',
    },
    { id: 'total', numeric: true, disablePadding: false, label: 'Total' },
  ];

  const Cells: LocalCellType[] = [
    {
      align: 'left',
      name: 'collected_on',
      hide: false,
    },
    {
      align: 'left',
      name: 'kiosk__name',
      hide: false,
    },
    {
      align: 'left',
      name: 'kiosk__location__name',
      hide: false,
    },
    {
      align: 'left',
      name: 'full_name',
      hide: true,
    },
    {
      align: 'right',
      name: 'total',
      hide: true,
      apply: moneyFormatter,
    },
  ];

  const removeKioskFilter = () => {
    setFilterLocation({ facility: filterLocation.facility, kiosk: nullAgent });
    setFiltersRefresh(!filtersRefresh);
  };
  const removeFacilityFilter = () => {
    setFilterLocation({ facility: nullAgent, kiosk: nullAgent });
    setFiltersRefresh(!filtersRefresh);
  };
  const removeDateRange = () => {
    setFilterStartDate(null);
    setFilterEndDate(null);
    setFiltersRefresh(!filtersRefresh);
  };

  const FilterChips: FilterChip[] = [
    {
      label: `Agent: ${filterAgent.name}`,
      onClose: () => {
        setFilterAgent(nullAgent);
        setFiltersRefresh(!filtersRefresh);
      },
      applied: filterAgent.pk !== -1,
      icon: BallotIcon,
    },
    {
      label: `Facility: ${filterLocation.facility.name}`,
      onClose: removeFacilityFilter,
      applied: filterLocation.facility.pk !== -1,
      icon: AccountBalanceIcon,
    },
    {
      label: `Kiosk: ${filterLocation.kiosk.name}`,
      onClose: removeKioskFilter,
      applied: filterLocation.kiosk.pk !== -1,
      icon: RemoveFromQueueIcon,
    },
    {
      label:
        filterStartDate === filterEndDate
          ? `Date: ${filterStartDate}`
          : `Date range: ${filterStartDate} to ${filterEndDate}`,
      onClose: removeDateRange,
      applied: Boolean(filterStartDate && filterEndDate),
      icon: DateRangeIcon,
    },
  ];

  return (
    <div id="table">
      <CustomTable
        header="Collections"
        csvType="collections"
        tableSize={'lg'}
        fetchRows={fetchRows}
        headCellsAll={headCellsAll}
        defaultOrderBy={'collected_on'}
        Cells={Cells}
        loading={loading}
        setLoading={setLoading}
        Details={Details}
        filtersURL={getURLFilters()}
        Filters={FiltersWrapper}
        FiltersToolbar={FiltersToolbar}
        FilterChips={FilterChips}
        filtersRefresh={filtersRefresh}
      />
    </div>
  );
};

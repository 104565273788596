import { useSelector } from 'react-redux';
import Error404 from './404';
import React, { useState } from 'react';
import CustomTable from '../components/tables/table';
import { nullAgent, SERVER_URL } from '../global';
import { moneyFormatter } from '../components/tables/utils';
import { Details } from '../components/tables/cashLevel/details';
import Filters from '../components/tables/cashLevel/filters';
import FiltersToolbar from '../components/tables/filtersToolbar';
import {
  CashLevelData as Data,
  FetchProps,
  Location,
  CellType,
  HeadCell,
  FilterChip,
} from '../components/tables/types';
import { fetchWithAuth } from '../components/tables/utils';
import RemoveFromQueueIcon from '@material-ui/icons/RemoveFromQueue';

import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { StoreState } from '../redux/types';

interface LocalCellType extends CellType {
  name: keyof Data;
}
interface LocalHeadCellType extends HeadCell {
  id: keyof Data;
}

export const CashLevel = () => {
  const [loading, setLoading] = useState(false);
  const [filterLocation, setFilterLocation] = useState<Location>({
    kiosk: nullAgent,
    facility: nullAgent,
  });
  const [filtersRefresh, setFiltersRefresh] = useState(false);

  const FiltersWrapper = ({ setShowFilters }: any) => (
    <Filters
      filtersRefresh={filtersRefresh}
      filterLocation={filterLocation}
      setFilterLocation={setFilterLocation}
      setShowFilters={setShowFilters}
      setFiltersRefresh={setFiltersRefresh}
    />
  );

  const getURLFilters = () => {
    let URL = '';
    if (filterLocation.kiosk.pk !== -1) {
      URL += `&kiosk=${filterLocation.kiosk.pk}`;
    }
    if (filterLocation.facility.pk > -1) {
      URL += `&facility=${filterLocation.facility.pk}`;
    }
    return URL;
  };

  const fetchRows = async ({
    order,
    orderBy,
    page,
    setRows,
    rowsPerPage,
    setCount,
    setLoading,
    setError,
  }: FetchProps) => {
    setLoading(true);
    let URL =
      SERVER_URL +
      'cash-alert/' +
      order +
      orderBy +
      '/' +
      `?page=${page + 1}&limit=${rowsPerPage}`;
    URL += getURLFilters();
    fetchWithAuth(URL, setRows, setCount, setLoading, setError);
  };

  const headCellsAll: LocalHeadCellType[] = [
    {
      id: 'kiosk__location__name',
      numeric: false,
      disablePadding: false,
      label: 'Facility',
    },
    {
      id: 'kiosk__name',
      numeric: false,
      disablePadding: false,
      label: 'Kiosk',
    },
    { id: 'total', numeric: true, disablePadding: false, label: 'Total' },
    {
      id: 'bill_count',
      numeric: true,
      disablePadding: false,
      label: 'Bills Count',
    },
    {
      id: 'coin_count',
      numeric: true,
      disablePadding: false,
      label: 'Coins Count',
    },
  ];

  const Cells: LocalCellType[] = [
    {
      align: 'left',
      name: 'kiosk__location__name',
      hide: false,
    },
    {
      align: 'left',
      name: 'kiosk__name',
      hide: false,
    },
    {
      align: 'right',
      name: 'total',
      hide: false,
      apply: moneyFormatter,
    },
    {
      align: 'right',
      name: 'bill_count',
      hide: true,
    },
    {
      align: 'right',
      name: 'coin_count',
      hide: true,
    },
  ];

  const removeKioskFilter = () => {
    setFilterLocation({ facility: filterLocation.facility, kiosk: nullAgent });
    setFiltersRefresh(!filtersRefresh);
  };
  const removeFacilityFilter = () => {
    setFilterLocation({ facility: nullAgent, kiosk: nullAgent });
    setFiltersRefresh(!filtersRefresh);
  };

  const FilterChips: FilterChip[] = [
    {
      label: `Facility: ${filterLocation.facility.name}`,
      onClose: removeFacilityFilter,
      applied: filterLocation.facility.pk !== -1,
      icon: AccountBalanceIcon,
    },
    {
      label: `Kiosk: ${filterLocation.kiosk.name}`,
      onClose: removeKioskFilter,
      applied: filterLocation.kiosk.pk !== -1,
      icon: RemoveFromQueueIcon,
    },
  ];

  const profile = useSelector((state: StoreState) => state.profile);
  if (['agent', 'falicityadmin'].includes(profile!.role!)) {
    return Error404();
  }

  return (
    <div id="table">
      <CustomTable
        header="Cash Levels"
        csvType="cash-levels"
        tableSize={'lg'}
        fetchRows={fetchRows}
        headCellsAll={headCellsAll}
        defaultOrderBy={'kiosk__location__name'}
        defaultOrder={""}
        Cells={Cells}
        loading={loading}
        setLoading={setLoading}
        Details={Details}
        filtersURL={getURLFilters()}
        Filters={FiltersWrapper}
        FiltersToolbar={FiltersToolbar}
        FilterChips={FilterChips}
        filtersRefresh={filtersRefresh}
      />
    </div>
  );
};

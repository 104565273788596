import { setProfile, Profile } from '../types';

const INITIAL_STATE: Profile = {
  username: '',
  firstName: '',
  lastName: '',
  email: '',
  role: '',
  timezone: '',
};

const profileReducer = (state: Profile = INITIAL_STATE, action: setProfile) => {
  switch (action.type) {
    case 'SET_PROFILE':
      if (action.payload) {
        const result = {
          username: action.payload.username,
          firstName: action.payload.first_name,
          lastName: action.payload.last_name,
          email: action.payload.email,
          role: action.payload.role,
          timezone: action.payload.timezone,
        };
        return result;
      } else {
        return INITIAL_STATE;
      }

    default:
      return state;
  }
};

export default profileReducer;

import { Box, Container, Paper, Typography } from '@material-ui/core';
import React, { useState } from 'react';

import Button from '@material-ui/core/Button';

import theme from '../../../theme';
import { SERVER_URL, nullAgent } from '../../../global';

import { Agent, Kiosk, Location } from '../types';
import 'date-fns';

import { StoreState } from '../../../redux/types';
import { useSelector } from 'react-redux';
import {
  DateRangeAccordion,
  LocationAccordion,
  ServiceTypeAccordion,
  SuccessfulAccordion,
} from '../filterAccordions';
import { useStyles } from '../styles';
import { getDate, convertDateToUTC } from '../utils'

type AgentsResponse = {
  results: Agent[];
};

type Props = {
  filterStartDate: string | null;
  filterEndDate: string | null;
  filterLocation: Location;
  filterServiceType: number;
  filtersRefresh: boolean;
  filterSuccessful: 'all' | 'successful' | 'failed';
  setFilterSuccessful: Function;
  setFilterServiceType: Function;
  setFilterLocation: Function;
  setFilterStartDate: Function;
  setFilterEndDate: Function;
  setShowFilters: Function;
  setFiltersRefresh: Function;
};

const Filters = ({
  filterEndDate,
  filterStartDate,
  filterLocation,
  filterServiceType,
  filtersRefresh,
  filterSuccessful,
  setFilterSuccessful,
  setFilterServiceType,
  setFilterLocation,
  setShowFilters,
  setFilterEndDate,
  setFilterStartDate,
  setFiltersRefresh,
}: Props) => {
  const classes = useStyles(theme);

  let stDate = null,
    enDate = null;
  if (filterStartDate) stDate = convertDateToUTC(new Date(String(filterStartDate)));
  if (filterEndDate) enDate = convertDateToUTC(new Date(String(filterEndDate)));
  const [startDate, setStartDate] = useState<Date | null>(stDate);
  const [endDate, setEndDate] = useState<Date | null>(enDate);

  const [facilities, setFacilities] = useState<Agent[]>([]);
  const [facility, setFacility] = useState<Agent>(filterLocation.facility);
  const [kiosks, setKiosks] = useState<Agent[]>([]);
  const [kiosk, setKiosk] = useState<Kiosk>(filterLocation.kiosk);
  const [serviceType, setServiceType] = useState<number>(filterServiceType);
  const [successful, setSuccessful] = useState < 'all' | 'successful' | 'failed'>(filterSuccessful);

  const fetchInstances = async (URL: string, setData: Function) => {
    const response = await fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${localStorage.getItem('token')}`,
      },
    });
    const data: AgentsResponse = await response.json();
    setData(data.results);
  };

  const handleInstanceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setInstance: Function,
    array: any[]
  ) => {
    const currInstance = array.find(
      (obj: any) => String(obj.pk) === String(event.target.value)
    );
    if (currInstance) {
      setInstance(currInstance!);
    } else {
      setInstance(nullAgent);
    }
  };

  const handleStartDateChange = (date: Date | null) => {
    if (date) {
      if (!endDate) {
        setEndDate(date);
      }
      setStartDate(date);
    }
  };
  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      setEndDate(date);
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const role = useSelector((store: StoreState) => store.profile?.role);
  
  const applyChanges = () => {
    setFilterLocation({ facility, kiosk });
    if (startDate && endDate) {
      setFilterStartDate(getDate(startDate));
      setFilterEndDate(getDate(endDate));
    }
    setFilterServiceType(serviceType);
    setFilterSuccessful(successful);
    setFiltersRefresh(!filtersRefresh);
    setShowFilters(false);
  };
  const cancelChanges = () => {
    setShowFilters(false);
  };
  const clearChanges = () => {
    setFilterLocation({ kiosk: nullAgent, facility: nullAgent });
    setFilterSuccessful('all');
    setFiltersRefresh(!filtersRefresh);
    setShowFilters(false);
  };
  React.useEffect(() => {
    fetchInstances(SERVER_URL + 'facilities/', setFacilities);
  }, []);
  React.useEffect(() => {
    fetchInstances(SERVER_URL + `kiosks/${facility.pk}/`, setKiosks);
  }, [facility]);

  return (
    <Box>
      <Paper className={classes.root} elevation={3}>
        <Typography className={classes.header} variant="h6">
          Filters:
        </Typography>

        <LocationAccordion
          facilities={facilities}
          kiosk={kiosk}
          kiosks={kiosks}
          setFacility={setFacility}
          setKiosk={setKiosk}
          classes={classes}
          facility={facility}
          handleInstanceChange={handleInstanceChange}
        />

        <DateRangeAccordion
          classes={classes}
          startDate={startDate}
          endDate={endDate}
          handleEndDateChange={handleEndDateChange}
          handleStartDateChange={handleStartDateChange}
        />

        <ServiceTypeAccordion
          serviceType={serviceType}
          setServiceType={setServiceType}
          classes={classes}
        />

        <SuccessfulAccordion
          classes={classes}
          successful={successful}
          setSuccessful={setSuccessful}
          />

        <Container className={classes.btnContainer + ' ' + classes.cntPadding}>
          <Button onClick={clearChanges}>Clear</Button>
          <Container
            className={classes.cntPadding + ' ' + classes.btnsContainer}
          >
            <Button color="primary" variant="contained" onClick={applyChanges}>
              Apply
            </Button>
            <Button style={{ marginLeft: '10px' }} onClick={cancelChanges}>
              Cancel
            </Button>
          </Container>
        </Container>
      </Paper>
    </Box>
  );
};

export default Filters;

import React, { useCallback, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useStylesTable as useStyles } from './styles';
import theme from '../../theme';
import {
  Container,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  TablePagination,
  Button,
  Box,
} from '@material-ui/core';
import CustomToolbar from './toolbar';
import PrintTable from './printTable';
import { useState } from 'react';
import { Order, CellType, FilterChip } from './types';
import EnhancedTableHead from './enchancedTableHead';
import { downloadExportFile } from './downloadCSV';
import { MAX_PRINT_FETCH, SERVER_URL } from '../../global';
import AlertDialog from './dialog';
import { ROWS_PER_PAGE } from '../../global'

type Props = {
  header: string;
  fetchRows: Function;
  defaultOrderBy: string;
  defaultOrder?: "-" | "";
  headCellsAll: any;
  loading: boolean;
  setLoading: Function;
  Cells: CellType[];
  tableSize: 'sm' | 'md' | 'lg' | 'xl';
  Details: Function;
  FiltersToolbar: Function;
  FilterChips: FilterChip[];
  Filters: Function;
  filtersRefresh: boolean;
  csvType: string;
  filtersURL: string;
};

const CustomTable = ({
  header,
  fetchRows,
  defaultOrderBy,
  headCellsAll,
  loading,
  setLoading,
  Cells,
  tableSize,
  Details,
  FiltersToolbar,
  FilterChips,
  Filters,
  filtersRefresh,
  csvType,
  filtersURL,
  defaultOrder,
}: Props) => {
  interface HeadCell {
    disablePadding: boolean;
    id: string;
    label: string;
    numeric: boolean;
  }
  const classes = useStyles(theme);
  const [showFilters, setShowFilters] = useState(false);
  const [order, setOrder] = useState<Order>(defaultOrder !== undefined ? defaultOrder : '-');
  const [orderBy, setOrderBy] = useState<string>(defaultOrderBy);
  const [headCells, setHeadCells] = useState<HeadCell[]>(headCellsAll);
  const [selected, setSelected] = useState<number | undefined>(undefined);
  const [rows, setRows] = useState<any[]>([]);
  const [isMounted, setIsMounted] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [error, setError] = useState(false);

  const [printData, setPrintData] = useState({ results: [], headers: [] });
  const [openDialog, setOpenDialog] = useState(false);
  const [printing, isPrinting] = useState(false)
  const [loadingCSV, isLoadingCSV] = useState(false)
  const printRef = useRef(null);

  const fetchBeforePrint = React.useCallback(async () => {
    try {
      const response = await fetch(
        `${SERVER_URL}print/${order}${orderBy}/?type=${csvType}${filtersURL}`,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem('token')}`,
          },
        }
      );
      if (response.status === 200) {
        const data = await response.json();
        return data
      }
      if (response.status === 204) {
        return false
      }
    }
    catch {
      return false
    }
  }, [filtersURL, order, orderBy])

  const checkBeforePrint = React.useCallback(async () => {

    if (count < MAX_PRINT_FETCH) {
      const data = await fetchBeforePrint()
      if (data) {
        setPrintData(data);
        handlePrint && handlePrint();
      } else {
        isPrinting(false)
      }
    } else {
      setOpenDialog(true);
    }

  }, [filtersURL, order, orderBy, count]);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => isPrinting(false)
  });

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === '';
    setOrder(isAsc ? '-' : '');
    setOrderBy(property);
  };

  const localFetchRows = () => {
    fetchRows({
      order,
      orderBy,
      page,
      rowsPerPage,
      setRows,
      setCount,
      setLoading,
      setError,
    });
    // eslint-disable-next-line
  }

  const onRefresh = () => {
    setPage(0);
    localFetchRows();
  };

  const checkIfSelected = (index?: number) => {
    if (index !== undefined && index === selected) {
      return true;
    }
    return false;
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const closeDetails = useCallback(() => {
    setHeadCells(headCellsAll);
    setSelected(undefined);
    // eslint-disable-next-line
  }, []);

  const handleClick = (event: React.MouseEvent<unknown>, index?: number) => {
    setShowFilters(false);
    if (!(index === undefined)) {
      setHeadCells(headCellsAll.slice(0, 3));
    } else {
      setHeadCells(headCellsAll);
    }
    setSelected(index);
  };

  // Handles fetching onMount
  React.useEffect(() => {
    setIsMounted(true);
    localFetchRows();
    // eslint-disable-next-line
  }, []);
  // Handles fetching without resetting pagination
  React.useEffect(() => {
    if (isMounted) {
      localFetchRows();
      closeDetails();
    }
    // eslint-disable-next-line
  }, [page, rowsPerPage, closeDetails]);
  // Handles fetching + resets page
  React.useEffect(() => {
    if (isMounted) {
      setPage(0);
      localFetchRows();
    }
    // eslint-disable-next-line
  }, [orderBy, order, filtersRefresh]);
  const noFilters = FilterChips.every((item) => !item.applied);
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length);
  return (
    <div className={classes.root}>
      <AlertDialog open={openDialog} setOpen={setOpenDialog} handlePrint={handlePrint} setPrinting={isPrinting}
        fetchBeforePrint={fetchBeforePrint} setPrintData={setPrintData} />
      <Box display="none">
        <PrintTable ref={printRef} data={printData} header={header} FilterChips={FilterChips} />
      </Box>
      <Container maxWidth={tableSize}>
        <Paper className={classes.paper}>
          <CustomToolbar
            classes={classes}
            header={header}
            onRefresh={onRefresh}
            setShowFilters={setShowFilters}
            showFilters={showFilters}
            closeDetails={closeDetails}
          />
          {!noFilters && (
            <FiltersToolbar
              FilterChips={FilterChips}
              setShowFilters={setShowFilters}
            />
          )}
          <Box position='relative'>

          {showFilters && <Filters setShowFilters={setShowFilters} />}
          </Box>
          <Container style={{ display: 'flex' }}>
            <TableContainer classes={{ root: classes.tContainer }}>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size="medium"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  headCells={headCells}
                />
                {!loading ? (
                  error ? (
                    <TableBody>
                      <TableRow>
                        <TableCell>Error</TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                      <TableBody>
                        {rows &&
                          rows.map((row, index) => {
                            const isItemSelected = checkIfSelected(index);
                            return (
                              <TableRow
                                selected={isItemSelected}
                                hover
                                onClick={(event) => {
                                  if (isItemSelected) {
                                    handleClick(event);
                                  } else {
                                    handleClick(event, index);
                                  }
                                }}
                                tabIndex={-1}
                                key={index}
                              >
                                {Cells.map((item) =>
                                  item.hide ? (
                                    selected === undefined && (
                                      <TableCell
                                        key={item.name}
                                        align={item.align}
                                      >
                                        {item.apply
                                          ? item.apply(row[item.name])
                                          : row[item.name]}
                                      </TableCell>
                                    )
                                  ) : (
                                      <TableCell key={item.name} align={item.align}>
                                        {item.apply
                                          ? item.apply(row[item.name])
                                          : row[item.name]}
                                      </TableCell>
                                    )
                                )}
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                    )
                ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell align="right">
                          {' '}
                          <div className="center">
                            <CircularProgress />
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
              </Table>
            </TableContainer>
            {!(selected === undefined) && (
              <Details data={rows[selected]} closeDetails={closeDetails} />
            )}
          </Container>
          <TablePagination
            rowsPerPageOptions={ROWS_PER_PAGE}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            classes={{ toolbar: classes.toolbar }}
          />
        </Paper>
        {!loadingCSV ? <Button
          className={classes.exportButton}
          color="primary"
          variant="contained"
          onClick={async () => {
            isLoadingCSV(true)
            await downloadExportFile(csvType, filtersURL, order+orderBy);
            isLoadingCSV(false)
          }}
        >
          Export CSV
        </Button>
        :
          <Box className={classes.exportButton}>
            <CircularProgress />
          </Box>
          }
        {!printing ?
          <Button
            className={classes.exportButton}
            color="primary"
            variant="contained"
            onClick={() => {
              handlePrint && isPrinting(true);
              checkBeforePrint();
            }}
          >
            Print
        </Button>
          :
          <Box className={classes.exportButton}>
            <CircularProgress />
          </Box>
        }
      </Container>
    </div>
  );
};

export default CustomTable;

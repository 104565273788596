import React, { useState } from 'react';
import CustomTable from '../components/tables/table';
import { nullAgent, SERVER_URL } from '../global';
import {
  getServiceType,
  moneyFormatter,
  getPaymentType,
  booleanConverter,
} from '../components/tables/utils';
import { Details } from '../components/tables/transactions/details';
import FiltersToolbar from '../components/tables/filtersToolbar';
import {
  TransactionData as Data,
  Location,
  CellType,
  HeadCell,
  FilterChip,
  FetchProps,
} from '../components/tables/types';
import { fetchWithAuth } from '../components/tables/utils';
import RemoveFromQueueIcon from '@material-ui/icons/RemoveFromQueue';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import BallotIcon from '@material-ui/icons/Ballot';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Filters from '../components/tables/transactions/filters';

interface TransactionCellType extends CellType {
  name: keyof Data;
}
interface TransactionHeadCellType extends HeadCell {
  id: keyof Data;
}

export const Transactions = () => {
  const [loading, setLoading] = useState(false);
  const [filterStartDate, setFilterStartDate] = useState<string | null>(null);
  const [filterEndDate, setFilterEndDate] = useState<string | null>(null);
  const [filterLocation, setFilterLocation] = useState<Location>({
    kiosk: nullAgent,
    facility: nullAgent,
  });
  const [filterSuccessful, setFilterSuccessful] = useState<'all' | 'successful' | 'failed'>('all');
  const [filtersRefresh, setFiltersRefresh] = useState(false);
  const [filterServiceType, setFilterServiceType] = useState<number>(-1);

  const FiltersWrapper = ({ setShowFilters }: any) => (
    <Filters
      filtersRefresh={filtersRefresh}
      filterEndDate={filterEndDate}
      filterLocation={filterLocation}
      filterStartDate={filterStartDate}
      filterServiceType={filterServiceType}
      filterSuccessful={filterSuccessful}
      setFilterSuccessful={setFilterSuccessful}
      setFilterEndDate={setFilterEndDate}
      setFilterLocation={setFilterLocation}
      setFilterServiceType={setFilterServiceType}
      setFilterStartDate={setFilterStartDate}
      setShowFilters={setShowFilters}
      setFiltersRefresh={setFiltersRefresh}
    />
  );

  const getURLFilters = () => {
    let URL = '';
    if (filterStartDate && filterEndDate) {
      URL += `&start_date=${filterStartDate}&end_date=${filterEndDate}`;
    }
    if (filterLocation.kiosk.pk !== -1) {
      URL += `&kiosk=${filterLocation.kiosk.pk}`;
    }
    if (filterLocation.facility.pk > -1) {
      URL += `&facility=${filterLocation.facility.pk}`;
    }
    if (filterServiceType > -1) {
      URL += `&service_type=${filterServiceType}`;
    }
    if (filterSuccessful !== 'all') {
      URL += `&status=${filterSuccessful}`;
    }
    return URL;
  };
  const fetchRows = async ({
    order,
    orderBy,
    page,
    setRows,
    rowsPerPage,
    setCount,
    setLoading,
    setError,
  }: FetchProps) => {
    setLoading(true);
    let URL =
      SERVER_URL +
      'transaction/' +
      order +
      orderBy +
      '/' +
      `?page=${page + 1}&limit=${rowsPerPage}`;
    URL += getURLFilters();
    fetchWithAuth(URL, setRows, setCount, setLoading, setError);
  };

  const headCellsAll: TransactionHeadCellType[] = [
    { id: 'created_on', numeric: false, disablePadding: true, label: 'Date' },
    {
      id: 'kiosk__name',
      numeric: false,
      disablePadding: false,
      label: 'Kiosk',
    },
    {
      id: 'kiosk__location__name',
      numeric: false,
      disablePadding: false,
      label: 'Facility',
    },
    {
      id: 'service_type',
      numeric: false,
      disablePadding: false,
      label: 'Service Type',
    },
    {
      id: 'inmate_first_name',
      numeric: false,
      disablePadding: false,
      label: 'First Name',
    },
    {
      id: 'inmate_last_name',
      numeric: false,
      disablePadding: false,
      label: 'Last Name',
    },
    {
      id: 'pin',
      numeric: false,
      disablePadding: false,
      label: 'PIN',
    },
    {
      id: 'payment_type',
      numeric: false,
      disablePadding: false,
      label: 'Payment Type',
    },
    {
      id: 'successful',
      numeric: false,
      disablePadding: true,
      label: 'Result',
    },
    {
      id: 'total',
      numeric: true,
      disablePadding: false,
      label: 'Total',
    },
  ];

  const Cells: TransactionCellType[] = [
    {
      align: 'left',
      name: 'created_on',
      hide: false,
    },
    {
      align: 'left',
      name: 'kiosk__name',
      hide: false,
    },
    {
      align: 'left',
      name: 'kiosk__location__name',
      hide: false,
    },
    {
      align: 'left',
      name: 'service_type',
      hide: true,
      apply: getServiceType,
    },
    {
      align: 'left',
      name: 'inmate_first_name',
      hide: true,
    },
    {
      align: 'left',
      name: 'inmate_last_name',
      hide: true,
    },
    {
      align: 'left',
      name: 'pin',
      hide: true,
    },
    {
      align: 'left',
      name: 'payment_type',
      hide: true,
      apply: getPaymentType,
    },
    {
      align: 'left',
      name: 'successful',
      hide: true,
      apply: booleanConverter,
    },
    {
      align: 'right',
      name: 'total',
      hide: true,
      apply: moneyFormatter,
    },
  ];

  const removeKioskFilter = () => {
    setFilterLocation({ facility: filterLocation.facility, kiosk: nullAgent });
    setFiltersRefresh(!filtersRefresh);
  };
  const removeFacilityFilter = () => {
    setFilterLocation({ facility: nullAgent, kiosk: nullAgent });
    setFiltersRefresh(!filtersRefresh);
  };
  const removeDateRange = () => {
    setFilterStartDate(null);
    setFilterEndDate(null);
    setFiltersRefresh(!filtersRefresh);
  };
  const removeSuccessfulFilter = () => {
    setFilterSuccessful('all');
    setFiltersRefresh(!filtersRefresh);
  }

  const FilterChips: FilterChip[] = [
    {
      label: `Facility: ${filterLocation.facility.name}`,
      onClose: removeFacilityFilter,
      applied: filterLocation.facility.pk !== -1,
      icon: AccountBalanceIcon,
    },
    {
      label: `Result: ${filterSuccessful==='successful'?"Successful":"Failed"}`,
      onClose: removeSuccessfulFilter,
      applied: filterSuccessful!=='all',
      icon: DoneAllIcon,
    },
    {
      label: `Kiosk: ${filterLocation.kiosk.name}`,
      onClose: removeKioskFilter,
      applied: filterLocation.kiosk.pk !== -1,
      icon: RemoveFromQueueIcon,
    },
    {
      label:
        filterStartDate === filterEndDate
          ? `Date: ${filterStartDate}`
          : `Date range: ${filterStartDate} to ${filterEndDate}`,
      onClose: removeDateRange,
      applied: Boolean(filterStartDate && filterEndDate),
      icon: DateRangeIcon,
    },
    {
      label: `Service Type: ${getServiceType(filterServiceType)}`,
      onClose: () => {
        setFilterServiceType(-1);
        setFiltersRefresh(!filtersRefresh);
      },
      applied: filterServiceType !== -1,
      icon: BallotIcon,
    },
  ];

  return (
    <div id="table">
      <CustomTable
        header="Transactions"
        csvType="transactions"
        tableSize={'lg'}
        fetchRows={fetchRows}
        defaultOrderBy={'created_on'}
        headCellsAll={headCellsAll}
        Cells={Cells}
        loading={loading}
        setLoading={setLoading}
        Details={Details}
        filtersURL={getURLFilters()}
        Filters={FiltersWrapper}
        FiltersToolbar={FiltersToolbar}
        FilterChips={FilterChips}
        filtersRefresh={filtersRefresh}
      />
    </div>
  );
};

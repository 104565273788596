import React from 'react';

type Props = {
  onLogout: any;
};

const Logout = ({ onLogout }: Props) => {
  return (
    <div id="logout">
      <button onClick={onLogout}>logout</button>
    </div>
  );
};

export default Logout;

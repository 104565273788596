import React from 'react'
import { serviceTypes } from '../../global';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

const getServiceType = (loadType: number) => serviceTypes[loadType];
const getAlertType = (loadType: number) =>
  ['Collection Past Due', 'Cash Level High', 'Cash Total High', 'Coin Level High'][loadType];
const getPSLoadType = (loadType: number) =>
  ['Inmate Account', 'Phone Number'][loadType];
const getPaymentType = (pt: number) => ['Credit', 'Cash'][pt];

const booleanConverter = (bool: boolean) => {
  if (bool) {
    return <CheckCircleIcon style={{ color: '#4caf50'}} />
  }
  return <CancelIcon color='error' />
}

const alphabeticSort = (a: string, b: string): number => {
  if (a < b) { return -1 }
  if (a > b) { return 1 }
  return 0;
}

const representationSort = (a: number, b: number, f: Function): number => {
  if (f(a) < f(b)) { return -1 }
  if (f(a) > f(b)) { return 1 }
  return 0;
}

const fetchWithAuth = async (
  URL: string,
  setRows: Function,
  setCount: Function,
  setLoading: Function,
  setError: Function
) => {
  try {
    const response = await fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${localStorage.getItem('token')}`,
      },
    });
    const data = await response.json();
    const code = response.status;
    if (code === 200) {
      setRows(data.results);
      setCount(data.count);
      setLoading(false);
      setError(false);
    } else {
      if (data.detail === 'Signature has expired.') {
        localStorage.removeItem('token');
      }
      setLoading(false);
      setError(true);
    }
  } catch (error) {
    setLoading(false);
    setError(true);
  }
};

const shortenString = (str: string) => {
  if (str.length >= 40) {
    str = str.slice(0, 37) + '...';
  }
  return str;
};

const formatPhoneNumber = (str: string) => {
  if (str.length === 10) {
    return str.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  } else {
    return str;
  }
};

const moneyFormatter = (num: number | string) => {
  if (typeof num == 'number') {
    const result = num
      .toFixed(2)
      .replace(/(\d)(?=(?:\d{3})+(?:\.|$))|(\.\d\d?)\d*$/g, function (
        m,
        s1,
        s2
      ) {
        return s2 || s1 + ',';
      });
    return '$' + result;
  } else {
    return '$' + num;
  }
};

// const getDate = (date: Date) => {
//   return `${date.getFullYear()}-${date.getUTCMonth() + 1
//     }-${date.getUTCDate()}`;
// };
const getDate = (date: Date) => {
  return `${date.getFullYear()}-${date.getMonth() + 1
    }-${date.getDate()}`;
};

function convertDateToUTC(date: Date) { return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()); }

export {
  getServiceType,
  getPSLoadType,
  getAlertType,
  fetchWithAuth,
  moneyFormatter,
  shortenString,
  getPaymentType,
  formatPhoneNumber,
  getDate,
  convertDateToUTC,
  booleanConverter,
  alphabeticSort,
  representationSort
};

import React from 'react';
import { SERVER_URL } from '../../global';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import theme from '../../theme';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { Paper } from '@material-ui/core';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(() => ({
  paper: {
    marginTop: theme.spacing(9),
    // marginLeft: '240px',
    maxWidth: '312px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    paddingBottom: '20px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0px',
      maxWidth: '300px',
    },
  },
  main: {
    maxWidth: '600px',
    marginTop: '100px',
    display: 'flex',
    height: '70vh',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  snackBar: {
    marginTop: '50px',
    marginLeft: '120px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0px',
    },
  },
}),{ index: 1 }
);

type SignInProps = {
  onLogin: Function;
};

export default function SignIn({ onLogin }: SignInProps) {
  const classes = useStyles(theme);
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [errorLogin, setErrorLogin] = React.useState(false);
  const [errorPassword, setErrorPassword] = React.useState(false);
  const [showSnackbar, setShowSnackbar] = React.useState(false);

  const handleUsernameChange = (e: any) => {
    setUsername(e.target.value);
  };
  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value);
  };
  const clickSubmit = (e: any) => {
    // Don't let submit button redirect
    e.preventDefault();
    if (username && password) {
      const data = { username, password };
      // Get Auth Token
      fetch(SERVER_URL + 'token-auth/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((result) => result.json())
        .then((data) => {
          if (data.token) {
            onLogin(data.token);
          } else {
            setShowSnackbar(true);
          }
        })
        .catch((err) => {
          if (err) {
            setShowSnackbar(true);
          }
        });
    } else {
      !username ? setErrorLogin(true) : setErrorLogin(false);
      !password ? setErrorPassword(true) : setErrorPassword(false);
    }
  };

  const handleClose = () => {
    setShowSnackbar(false);
  };

  return (
    <div id="table">
      <ThemeProvider theme={theme}>
        <Container component="main" className={classes.main}>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={showSnackbar}
            autoHideDuration={6000}
            onClose={handleClose}
            classes={{ root: classes.snackBar }}
          >
            <Alert onClose={handleClose} severity="error">
              Unable to log in with provided credentials
            </Alert>
          </Snackbar>
          <CssBaseline />
          <Paper elevation={3} className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="login"
                label="Login"
                name="login"
                autoComplete="login"
                autoFocus
                value={username}
                onChange={handleUsernameChange}
                error={errorLogin}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={handlePasswordChange}
                error={errorPassword}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={clickSubmit}
              >
                Sign In
              </Button>
              <Box textAlign="center">
                <Link to="/reset-password/" className="link">
                  Forgot password?
                </Link>
              </Box>
            </form>
          </Paper>
          <Box mt={8}></Box>
        </Container>
      </ThemeProvider>
    </div>
  );
}

import React, { useState } from 'react';
import CustomTable from '../components/tables/table';
import { nullAgent, SERVER_URL } from '../global';
import { Details } from '../components/tables/alerts/details';
import FiltersToolbar from '../components/tables/filtersToolbar';
import {
  AlertData as Data,
  FetchProps,
  Location,
  CellType,
  HeadCell,
  FilterChip,
} from '../components/tables/types';
import {
  fetchWithAuth,
  getAlertType,
  shortenString,
} from '../components/tables/utils';
import RemoveFromQueueIcon from '@material-ui/icons/RemoveFromQueue';
import DateRangeIcon from '@material-ui/icons/DateRange';
import BallotIcon from '@material-ui/icons/Ballot';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Filters from '../components/tables/alerts/filters';
import { useSelector } from 'react-redux';
import { StoreState } from '../redux/types';
import Error404 from './404';

interface LocalCellType extends CellType {
  name: keyof Data;
}
interface LocalHeadCellType extends HeadCell {
  id: keyof Data;
}

export const AlertHistory = () => {
  const [loading, setLoading] = useState(false);
  const [filterStartDate, setFilterStartDate] = useState<string | null>(null);
  const [filterEndDate, setFilterEndDate] = useState<string | null>(null);
  const [filterLocation, setFilterLocation] = useState<Location>({
    kiosk: nullAgent,
    facility: nullAgent,
  });
  const [filtersRefresh, setFiltersRefresh] = useState(false);
  const [filterAlertType, setFilterAlertType] = useState<number>(-1);
  const profile = useSelector((state: StoreState) => state.profile);
  if (['agent', 'falicityadmin'].includes(profile!.role!)) {
    return Error404();
  }
  const FiltersWrapper = ({ setShowFilters }: any) => (
    <Filters
      filtersRefresh={filtersRefresh}
      filterEndDate={filterEndDate}
      filterLocation={filterLocation}
      filterStartDate={filterStartDate}
      filterAlertType={filterAlertType}
      setFilterEndDate={setFilterEndDate}
      setFilterLocation={setFilterLocation}
      setFilterAlertType={setFilterAlertType}
      setFilterStartDate={setFilterStartDate}
      setShowFilters={setShowFilters}
      setFiltersRefresh={setFiltersRefresh}
    />
  );

  const getURLFilters = () => {
    let URL = '';
    if (filterStartDate && filterEndDate) {
      URL += `&start_date=${filterStartDate}&end_date=${filterEndDate}`;
    }
    if (filterLocation.kiosk.pk !== -1) {
      URL += `&kiosk=${filterLocation.kiosk.pk}`;
    }
    if (filterLocation.facility.pk > -1) {
      URL += `&facility=${filterLocation.facility.pk}`;
    }
    if (filterAlertType !== -1) {
      URL += `&alert_type=${filterAlertType}`;
    }
    return URL;
  };

  const fetchRows = async ({
    order,
    orderBy,
    page,
    setRows,
    rowsPerPage,
    setCount,
    setLoading,
    setError,
  }: FetchProps) => {
    setLoading(true);
    let URL =
      SERVER_URL +
      'alert/' +
      order +
      orderBy +
      '/' +
      `?page=${page + 1}&limit=${rowsPerPage}`;
    URL += getURLFilters();
    fetchWithAuth(URL, setRows, setCount, setLoading, setError);
  };

  const headCellsAll: LocalHeadCellType[] = [
    { id: 'created_on', numeric: false, disablePadding: true, label: 'Date' },
    {
      id: 'kiosk__name',
      numeric: false,
      disablePadding: false,
      label: 'Kiosk',
    },
    {
      id: 'kiosk__location__name',
      numeric: false,
      disablePadding: false,
      label: 'Facility',
    },
    {
      id: 'alert_type_display',
      numeric: false,
      disablePadding: false,
      label: 'Alert Type',
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: false,
      label: 'Description',
    },
  ];

  const Cells: LocalCellType[] = [
    {
      align: 'left',
      name: 'created_on',
      hide: false,
    },
    {
      align: 'left',
      name: 'kiosk__name',
      hide: false,
    },
    {
      align: 'left',
      name: 'kiosk__location__name',
      hide: false,
    },
    {
      align: 'left',
      name: 'alert_type',
      hide: true,
      apply: getAlertType,
    },
    {
      align: 'left',
      name: 'description',
      hide: true,
      apply: shortenString,
    },
  ];

  const removeKioskFilter = () => {
    setFilterLocation({ facility: filterLocation.facility, kiosk: nullAgent });
    setFiltersRefresh(!filtersRefresh);
  };
  const removeFacilityFilter = () => {
    setFilterLocation({ facility: nullAgent, kiosk: nullAgent });
    setFiltersRefresh(!filtersRefresh);
  };
  const removeDateRange = () => {
    setFilterStartDate(null);
    setFilterEndDate(null);
    setFiltersRefresh(!filtersRefresh);
  };

  const FilterChips: FilterChip[] = [
    {
      label: `Type: ${getAlertType(filterAlertType)}`,
      onClose: () => {
        setFilterAlertType(-1);
        setFiltersRefresh(!filtersRefresh);
      },
      applied: filterAlertType !== -1,
      icon: BallotIcon,
    },
    {
      label: `Facility: ${filterLocation.facility.name}`,
      onClose: removeFacilityFilter,
      applied: filterLocation.facility.pk !== -1,
      icon: AccountBalanceIcon,
    },
    {
      label: `Kiosk: ${filterLocation.kiosk.name}`,
      onClose: removeKioskFilter,
      applied: filterLocation.kiosk.pk !== -1,
      icon: RemoveFromQueueIcon,
    },
    {
      label:
        filterStartDate === filterEndDate
          ? `Date: ${filterStartDate}`
          : `Date range: ${filterStartDate} to ${filterEndDate}`,
      onClose: removeDateRange,
      applied: Boolean(filterStartDate && filterEndDate),
      icon: DateRangeIcon,
    },
  ];

  return (
    <div id="table">
      <CustomTable
        csvType="alerts"
        header="Alert History"
        tableSize={'lg'}
        fetchRows={fetchRows}
        headCellsAll={headCellsAll}
        defaultOrderBy={'created_on'}
        Cells={Cells}
        loading={loading}
        setLoading={setLoading}
        Details={Details}
        filtersURL={getURLFilters()}
        Filters={FiltersWrapper}
        FiltersToolbar={FiltersToolbar}
        FilterChips={FilterChips}
        filtersRefresh={filtersRefresh}
      />
    </div>
  );
};

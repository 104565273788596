import { Box, Typography } from '@material-ui/core';
import React, { PureComponent as Component } from 'react';
import { FilterChip } from './types';

type Props = {
  data: any;
  header: string;
  FilterChips: FilterChip[];
};

export default class PrintTable extends Component<Props> {
  render() {
    const { data, header, FilterChips } = this.props;
    const { headers, results } = data;
    const labels = FilterChips.map(item => item.applied && item.label).filter(item => item)

    const Title = () => (
      <Box display='flex' flexDirection='column' alignItems='center'>
        <Typography variant='h5'>
          {header} Report
        </Typography>
        <Typography variant='h6'>
          {labels.join(', ')}
        </Typography>
      </Box>
    )
    if (results.length === 0 || headers.length === 0) {
      return <div id="print"><Title /></div>;
    }
    const keys = Object.keys(results[0]);
    return (
      <div id="print">
        <Title />
        <table>
          <thead>
            <tr>
              {keys.map((item: string) => (
                <th key={item}>
                  {headers.hasOwnProperty(item) ? headers[item] : item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {results.map((item: { [x: string]: React.ReactNode }, index: string | number | null | undefined) => (
              <tr key={index}>
                {keys.map((value) => (
                  <td key={value}>{item[value]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

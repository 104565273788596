import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';



type AlertProps = {
    open: boolean;
    setOpen: Function;
    handlePrint: (() => void) | undefined;
    setPrinting: Function;
    setPrintData: Function;
    fetchBeforePrint: Function;
}
export default function AlertDialog({open, setOpen, handlePrint, setPrinting, fetchBeforePrint, setPrintData}: AlertProps) {

    const handleClose = async (printAnyway: boolean) => {
        if (printAnyway) {
            setOpen(false);
            const data = await fetchBeforePrint();
            setPrintData(data);
            handlePrint && handlePrint();
        } else {    
            setPrinting(false);
            setOpen(false);
        }
        // resolve&&resolve();
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={()=>handleClose(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Printing could take a while"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Due to the large number of records you&apos;re attempting to print it may take a while and cause the website to hang.  You can Cancel and apply filters to reduce the number of records or choose to Print anyways.
          </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>handleClose(false)} color="primary">
                        Cancel
          </Button>
                    <Button onClick={()=>handleClose(true)} color="primary" autoFocus>
                        Print
          </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStylesTable = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: theme.spacing(2),
      [theme.breakpoints.down('sm')]:{
        padding: '0px',
        paddingTop: theme.spacing(1),
      }
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
      [theme.breakpoints.down('sm')]:{
        padding: theme.spacing(1),
      }
    },
    table: {
      minWidth: 200,
      '& td':{
        fontSize: '0.849rem',
      }
      // position: 'relative',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      // width: 1,
    },
    tContainer: {
      // overflow: 'initial',
    },
    toolbar: {
      flexWrap: 'wrap',
    },
    exportButton: {
      float: 'right',
      marginBottom: '30px',
      marginLeft: '10px'
    },

  }),{ index: 1 }
);

const useStylesDetails = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'sticky',
      top: '60px',
      height: 'fit-content',
      minWidth: '275px',
      border: '1px solid lightgrey',
      display: 'flex',
      flexDirection: 'column',
      zIndex: 1,
      backgroundColor: 'white',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '-30px',
      },
    },
    crossIcon: {
      color: theme.palette.grey[600],
      alignSelf: 'flex-end',
      marginTop: '8px',
      marginRight: '9px',
      marginBottom: '-47px',
      cursor: 'pointer',
    },
    accRoot: {
      '& .MuiAccordionSummary-root': {
        backgroundColor: theme.palette.grey[100],
      },
    },
    header: {
      fontSize: 'inherit',
    },
    headerValue: {
      fontSize: 'inherit',
      color: theme.palette.grey[800],
      wordBreak: 'break-word'
    },
    textContainer: {
      fontSize: '18px',
      color: theme.palette.grey[600],
      textAlign: 'center',
      padding: '5px',
      margin: '5px',
      width: '100%',
    },
    sTextContainer: {
      fontSize: '14px',
      color: theme.palette.grey[600],
      textAlign: 'left',
      padding: '7px 20px',
      width: '100%',
    },
    aTextContainer: {
      fontSize: '14px',
      color: theme.palette.grey[600],
      textAlign: 'left',
      padding: '0px',
    },
    sideTextContainer: {
      padding: 0,
      marginLeft: 20,
      color: theme.palette.grey[600],
    },
    sHeader: {
      marginTop: 20,
      fontSize: '14px',
    },
    description: {
      maxWidth: '230px',
      overflowWrap: 'break-word',
    },
    accSummary: {
      padding: '0px',
      display: 'block',
    },
    media: {
      height: 0,
      paddingTop: '64%',
      cursor: 'pointer',
    },
    customizedButton: {
      position: 'absolute',
      right: theme.spacing(-3),
      top: theme.spacing(-3),
      backgroundColor: 'lightgray',
      color: 'gray',
    },
  }),{ index: 1 }
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      zIndex: 1,
      // top: '55px',
      right: '50px',
      minWidth: '300px',
    },
    accRoot: {
      '& .MuiAccordionSummary-root': {
        backgroundColor: theme.palette.grey[100],
      },
      '&.Mui-expanded': {
        margin: '0px',
      },
    },
    btnContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '5px 0px',
    },
    btnsContainer: {
      width: 'fit-content',
      margin: '0px',
    },
    datesContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    cntPadding: {
      paddingLeft: '10px',
      paddingRight: '10px',
    },
    select: {
      minWidth: '100px',
    },
    header: {
      margin: theme.spacing(1),
    },
  }),{ index: 1 }
);

export { useStyles, useStylesTable, useStylesDetails };

import React from 'react';
import { SERVER_URL, TZS } from '../global';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Typography from '@material-ui/core/Typography';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Theme from '../theme';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom';
import store from '../redux/store';
import { Profile as ProfileType } from '../redux/types';

import { setProfile as setProfileRedux } from '../redux/profile/profile.action';
const EMAIL_REG = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

// function Copyright() {
//   return (
//     <Typography variant="body2" color="textSecondary" align="center">
//       {'Copyright © '}
//       <Link color="inherit" href="https://www.ncic.com/">
//         NCIC
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(9),
    marginLeft: '150px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0px',
    },
  },
  main: {
    marginTop: '50px',
    display: 'flex',
    height: '70vh',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    color: theme.palette.primary.main,
    fontSize: '40px',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 1, 2),
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0px',
  },
  reset: {
    margin: theme.spacing(3, 1, 2),
    color: theme.palette.primary.main,
  },
  snackBar: {
    marginTop: '50px',
    marginLeft: '70px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0px',
    },
  },
}),{ index: 1 }
);

type ProfileProps = {
  profileRedux?: ProfileType;
  showSnackbar: 'success' | 'info' | 'warning' | 'error' | undefined;
  setShowSnackbar: Function;
  onLogout: Function;
};

export default function Profile({
  profileRedux,
  showSnackbar,
  setShowSnackbar,
  onLogout,
}: ProfileProps) {
  const classes = useStyles(Theme);
  const [profile, setProfile] = React.useState(profileRedux);

  const [errorFirstName, setErrorFirstName] = React.useState(false);
  const [errorLastName, setErrorLastName] = React.useState(false);
  const [errorEmail, setErrorEmail] = React.useState(false);
  const [errorTimezone, setErrorTimeZone] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorUsername, setErrorUsername] = React.useState(false);
  const [errorMessage] = React.useState(
    'Error saving settings. Try again later'
  );

  let copy;
  const handleProfileChange = (e: any, key: string) => {
    copy = { ...profile };
    // @ts-ignore
    copy[key] = e.target.value;
    // @ts-ignore
    setProfile(copy);
  };

  const testEmail = (email: string) => {
    return EMAIL_REG.test(String(email).toLowerCase())
  }
  const profileValidate = () => {
    const fieldsAreFilled = !Object.values(profile!).some((el: string) => el.length === 0);
    let emailCorrect = false
    if (fieldsAreFilled && profile?.email){
      emailCorrect = testEmail(profile?.email)
    }
    return fieldsAreFilled && emailCorrect
  };

  const clickReset = (e: any) => {
    // Don't let submit button redirect
    e.preventDefault();
    setProfile(profileRedux);
  };

  const clickSubmit = async (e: any) => {
    // Don't let submit button redirect
    e.preventDefault();
    if (profileValidate()) {
      try {
        const response = await fetch(SERVER_URL + 'profile/me/', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify({
            // username: profile?.username,
            first_name: profile?.firstName,
            last_name: profile?.lastName,
            email: profile?.email,
            timezone: profile?.timezone,
          }),
        });
        const data = await response.json();
        const code = response.status;
        if (code === 200) {
          setShowSnackbar('success');
          store.dispatch(setProfileRedux(data));
        } else {
          if (data.detail === 'Signature has expired.') {
            onLogout();
          }
          setShowSnackbar('error');
        }
      } catch (error) {
        setShowSnackbar('error');
      }
    } else {
      // profile!.username ? setErrorUsername(false) : setErrorUsername(true);
      profile!.firstName ? setErrorFirstName(false) : setErrorFirstName(true);
      profile!.lastName ? setErrorLastName(false) : setErrorLastName(true);
      profile!.email ?  testEmail(profile!.email) ? setErrorEmail(false) : setErrorEmail(true): setErrorEmail(true);
      profile!.timezone ? setErrorTimeZone(false) : setErrorTimeZone(true);
    }
  };

  const handleClose = () => {
    setShowSnackbar(undefined);
  };
  const history = useHistory();

  const getTimeZone: any = {
    "Hawaii Standard Time (UTC -10)": "Pacific/Honolulu",
    "Alaska Standard Time (UTC -9)": "America/Anchorage",
    "Pacific Standard Time (UTC -8)": "America/Los_Angeles",
    "Mountain Standard Time (UTC -7)": "America/Boise",
    "Central Standard Time (UTC -6)": "America/Chicago",
    "Eastern Standard Time (UTC -5)": "America/New_York",
  }

  return (
    <ThemeProvider theme={Theme}>
      <Container component="main" className={classes.main} maxWidth="sm">
        {showSnackbar && (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={Boolean(showSnackbar)}
            autoHideDuration={6000}
            onClose={handleClose}
            classes={{ root: classes.snackBar }}
            // style={{ marginTop: '50px', marginLeft: '70px' }}
          >
            <Alert onClose={handleClose} severity={showSnackbar}>
              {showSnackbar === 'success' ? 'Changes were saved!' : errorMessage}
            </Alert>
          </Snackbar>
        )}
        <CssBaseline />
        <div className={classes.paper}>
          <AccountCircleIcon className={classes.avatar} />
          <Typography component="h1" variant="h5">
            Profile
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              disabled
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              value={profile?.username}
              onChange={(e) => handleProfileChange(e, 'username')}
              error={errorUsername}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="firstName"
              label="First Name"
              name="firstName"
              autoComplete="firstName"
              value={profile!.firstName}
              onChange={(e) => handleProfileChange(e, 'firstName')}
              error={errorFirstName}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              autoComplete="lastName"
              value={profile!.lastName}
              onChange={(e) => handleProfileChange(e, 'lastName')}
              error={errorLastName}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              value={profile!.email}
              onChange={(e) => handleProfileChange(e, 'email')}
              error={errorEmail}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              select
              id="timezone"
              label="Time Zone"
              name="timezone"
              autoComplete="timezone"
              value={profile!.timezone}
              onChange={(e) => handleProfileChange(e, 'timezone')}
              error={errorTimezone}
            >
              {TZS.map((option) => (
                <MenuItem key={getTimeZone[option]} value={getTimeZone[option]}>
                  {option}
                </MenuItem>
              ))} 
            </TextField>
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Container className={classes.btnContainer} maxWidth={'lg'}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className={classes.reset}
                onClick={clickReset}
              >
                Reset
              </Button>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={clickSubmit}
              >
                Save
              </Button>
            </Container>
            <Grid container>
              <Grid item xs>
                <Link
                  onClick={() => {
                    history.push('/change-password/');
                  }}
                  variant="body2"
                >
                  Change password
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={8}></Box>
      </Container>
    </ThemeProvider>
  );
}

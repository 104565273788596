import { combineReducers } from 'redux';

import profileReducer from './profile/profile.reducer';

const appReducer = combineReducers({
  profile: profileReducer,
});

const rootReducer = (state: any, action: any) => {
  return appReducer(state, action);
};
// @ts-ignore
export default (state, action) =>
  rootReducer(action.type === 'RESET' ? undefined : state, action);

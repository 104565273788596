import { createTheme } from '@material-ui/core/styles';

const theme = createTheme ({
  palette: {
    primary: {
      main: '#25408F',
      light: '#56ccf2',
    },
    secondary: {
      main: '#333333',
    },
  },
});

export default theme;

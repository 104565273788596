import React, { useState } from 'react';
import { SERVER_URL } from '../global';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Typography from '@material-ui/core/Typography';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Theme from '../theme';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom';

import { Passwords } from '../redux/types';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(9),
    marginLeft: '150px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0px',
    },
  },
  main: {
    marginTop: '50px',
    display: 'flex',
    height: '70vh',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    color: theme.palette.primary.main,
    fontSize: '40px',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 1, 2),
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0px',
  },
  reset: {
    margin: theme.spacing(3, 1, 2),
    color: theme.palette.primary.main,
  },
  snackBar: {
    marginTop: '50px',
    marginLeft: '70px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0px',
    },
  },
}),{ index: 1 }
);

type PasswordProps = {
  onLogout: Function;
  reset?: boolean;
  token?: string;
};

export default function ChangePassword({
  onLogout,
  reset,
  token,
}: PasswordProps) {
  const classes = useStyles(Theme);
  const [passwordError, setPasswordError] = useState(false);
  const [repeatError, setRepeatError] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState<any>();
  const [password, setPassword] = useState<Passwords>({
    password: '',
    repeat: '',
  });
  const [resetSuccess, setResetSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    'Error saving settings. Try again later'
  );
  const history = useHistory();
  let copy;
  const handlePasswordChange = (e: any, key: string) => {
    copy = { ...password };
    // @ts-ignore
    copy[key] = e.target.value;
    // @ts-ignore
    setPassword(copy);
  };

  const passwordValidate = () => {
    return (
      password.password === password.repeat &&
      Object.values(password).some((el: string) => el.length >= 8)
    );
  };

  const clickBack = (e: any) => {
    // Don't let submit button redirect
    e.preventDefault();
    const backLink = reset ? '/' : '/profile/';
    history.push(backLink);
  };

  const clickReset = async (e: any) => {
    // Don't let submit button redirect
    e.preventDefault();
    if (passwordValidate()) {
      try {
        const response = await fetch(
          SERVER_URL + 'api/password_reset/confirm/',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              password: password.password,
              token: token,
            }),
          }
        );
        const code = response.status;
        if (code === 200) {
          setShowSnackbar('success');
          setPassword({ password: '', repeat: '' });
          setPasswordError(false);
          setRepeatError(false);
          setResetSuccess(true);
          setTimeout(() => history.push('/'), 2000);
        } else {
          const data = await response.json();
          if (data.detail === 'Signature has expired.') {
            onLogout();
          }
          if (data.password) {
            let errMsg = 'Password is not correct: \n';
            for (let i = 0; i < data.password.length; i++) {
              errMsg = errMsg + `\n${i + 1}. ${data.password[i]}`;
            }
            setErrorMessage(errMsg);
            setShowSnackbar('error');
          } else {
            setErrorMessage('Password is not correct');
            setShowSnackbar('error');
          }
        }
      } catch (error) {
        setShowSnackbar('error');
      }
    } else {
      if (password.password !== password.repeat) {
        setPasswordError(true);
        setRepeatError(true);
        setErrorMessage('Passwords are not equal');
        setTimeout(() => setShowSnackbar('error'), 200);
      } else {
        if (password.password.length <= 8) {
          setPasswordError(true);
          setRepeatError(true);
          setErrorMessage('Password is too short');
          setShowSnackbar('error');
        } else {
          setPasswordError(true);
          setRepeatError(true);
          setErrorMessage('Error saving settings. Try again later');
          setShowSnackbar('error');
        }
      }
    }
  };

  const clickSubmit = async (e: any) => {
    // Don't let submit button redirect
    e.preventDefault();
    if (passwordValidate()) {
      try {
        const response = await fetch(SERVER_URL + 'update_password/', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify({
            new_password: password.password,
          }),
        });
        const code = response.status;
        if (code === 204) {
          setShowSnackbar('success');
          setPassword({ password: '', repeat: '' });
          setPasswordError(false);
          setRepeatError(false);
        } else {
          const data = await response.json();
          if (data.detail === 'Signature has expired.') {
            onLogout();
          }
          if (data.new_password) {
            let errMsg = 'Password is not correct: \n';
            for (let i = 0; i < data.new_password.length; i++) {
              errMsg = errMsg + `\n${i + 1}. ${data.new_password[i]}`;
            }
            setErrorMessage(errMsg);
            setShowSnackbar('error');
          } else {
            setErrorMessage('Password is not correct');
            setShowSnackbar('error');
          }
        }
      } catch (error) {
        setShowSnackbar('error');
      }
    } else {
      if (password.password !== password.repeat) {
        setPasswordError(true);
        setRepeatError(true);
        setErrorMessage('Passwords are not equal');
        setTimeout(() => setShowSnackbar('error'), 200);
      } else {
        if (password.password.length <= 8) {
          setPasswordError(true);
          setRepeatError(true);
          setErrorMessage('Password is too short');
          setShowSnackbar('error');
        } else {
          setPasswordError(true);
          setRepeatError(true);
          setErrorMessage('Error saving settings. Try again later');
          setShowSnackbar('error');
        }
      }
    }
  };

  const handleClose = () => {
    setShowSnackbar(undefined);
  };
  if (resetSuccess) {
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={Boolean(showSnackbar)}
        autoHideDuration={8000}
        onClose={handleClose}
        classes={{ root: classes.snackBar }}
      >
        <Alert onClose={handleClose} severity={showSnackbar}>
          {showSnackbar === 'success' ? 'Changes saved!' : errorMessage}
        </Alert>
      </Snackbar>
    );
  }
  return (
    <ThemeProvider theme={Theme}>
      <Container component="main" className={classes.main} maxWidth="sm">
        {showSnackbar && (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={Boolean(showSnackbar)}
            autoHideDuration={8000}
            onClose={handleClose}
            classes={{ root: classes.snackBar }}
          >
            <Alert onClose={handleClose} severity={showSnackbar}>
              {showSnackbar === 'success' ? 'Changes saved!' : errorMessage}
            </Alert>
          </Snackbar>
        )}
        <CssBaseline />
        <div className={classes.paper}>
          <AccountCircleIcon className={classes.avatar} />
          <Typography component="h1" variant="h5">
            {reset ? 'Enter New' : 'Change'} Password
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password"
              label="Password"
              name="password"
              autoComplete="password"
              type="password"
              value={password.password}
              onChange={(e) => handlePasswordChange(e, 'password')}
              error={passwordError}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              type="password"
              fullWidth
              id="confirm_password"
              label="Confirm Password"
              name="confirm password"
              autoComplete="confirm_password"
              value={password.repeat}
              onChange={(e) => handlePasswordChange(e, 'repeat')}
              error={repeatError}
            />
            <Container className={classes.btnContainer} maxWidth={'lg'}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className={classes.reset}
                onClick={clickBack}
              >
                Back
              </Button>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={reset ? clickReset : clickSubmit}
              >
                {reset ? 'Reset' : 'Save'}
              </Button>
            </Container>
          </form>
        </div>
        <Box mt={8}></Box>
      </Container>
    </ThemeProvider>
  );
}

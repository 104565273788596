import theme from '../../../theme';
import React, { useState } from 'react';
import { CollectionData as Data } from '../types';
import {
  Box,
  Container,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import { moneyFormatter } from '../utils';
import { useStylesDetails as useStyles } from '../styles';

type DetailsProps = {
  data: Data;
  closeDetails: Function;
};

export const Details = ({ data, closeDetails }: DetailsProps) => {
  const classes = useStyles(theme);
  const [openSummary, setOpenSummary] = useState(true);

  return (
    <Box className={classes.root}>
      <Tooltip title="Close">
        <IconButton
          className={classes.crossIcon}
          onClick={() => closeDetails()}
          aria-label="close details"
        >
          <CloseIcon />
        </IconButton>
      </Tooltip>
      <Container className={classes.textContainer}>
        <Typography className={classes.header}>Collection ID</Typography>
        <Typography className={classes.headerValue}>{data.pk}</Typography>
      </Container>
      <Accordion
        expanded={openSummary}
        onChange={() => {
          setOpenSummary(!openSummary);
        }}
        classes={{ root: classes.accRoot }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.header}>Summary</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accSummary}>
          <Container className={classes.sTextContainer}>
            <Typography className={classes.header}>Kiosk</Typography>
            <Typography className={classes.headerValue}>
              {data.kiosk__name}
            </Typography>
          </Container>
          <Container className={classes.sTextContainer}>
            <Typography className={classes.header}>Agent</Typography>
            <Typography className={classes.headerValue}>
              {data.full_name}
            </Typography>
          </Container>
          <Container className={classes.sTextContainer}>
            <Typography className={classes.header}>Username</Typography>
            <Typography className={classes.headerValue}>
              {data.agent__username}
            </Typography>
          </Container>
          <Container className={classes.sTextContainer}>
            <Typography className={classes.header}>
              Date of collection
            </Typography>
            <Typography className={classes.headerValue}>
              {data.collected_on}
            </Typography>
          </Container>
          <Container className={classes.sTextContainer}>
            <Typography className={classes.header}>Total</Typography>
            <Typography className={classes.headerValue}>
              {moneyFormatter(data.total)}
            </Typography>
          </Container>
        </AccordionDetails>
      </Accordion>
      <Container className={classes.aTextContainer}>
        <Accordion classes={{ root: classes.accRoot }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.header}>Denominations</Typography>
          </AccordionSummary>

          <AccordionDetails>
              <Typography className={classes.header}>Bills</Typography>
            </AccordionDetails>
          <AccordionDetails>
            <Container className={classes.sideTextContainer}>
              <Typography className={classes.header}>$1</Typography>
              <Typography className={classes.headerValue}>
                {data.count_1}
              </Typography>

              <Typography className={(classes.header, classes.sHeader)}>
                $5
              </Typography>
              <Typography className={classes.headerValue}>
                {data.count_5}
              </Typography>
              <Typography className={(classes.header, classes.sHeader)}>
                $20
              </Typography>

              <Typography className={classes.headerValue}>
                {data.count_20}
              </Typography>
              <Typography className={(classes.header, classes.sHeader)}>
                $100
              </Typography>
              <Typography className={classes.headerValue}>
                {data.count_100}
              </Typography>
            </Container>
            <Container className={classes.sideTextContainer}>
              <Typography className={classes.header}>$2</Typography>
              <Typography className={classes.headerValue}>
                {data.count_2}
              </Typography>
              <Typography className={(classes.header, classes.sHeader)}>
                $10
              </Typography>
              <Typography className={classes.headerValue}>
                {data.count_10}
              </Typography>

              <Typography className={(classes.header, classes.sHeader)}>
                $50
              </Typography>
              <Typography className={classes.headerValue}>
                {data.count_50}
              </Typography>
            </Container>
          </AccordionDetails>

          <AccordionDetails>
              <Typography className={classes.header}>Coins</Typography>
            </AccordionDetails>
          <AccordionDetails>
            <Container className={classes.sideTextContainer}>
              <Typography className={classes.header}>$0.01</Typography>
              <Typography className={classes.headerValue}>
                {data.coin_01}
              </Typography>

              <Typography className={(classes.header, classes.sHeader)}>
                $0.10
              </Typography>
              <Typography className={classes.headerValue}>
                {data.coin_10}
              </Typography>
              <Typography className={(classes.header, classes.sHeader)}>
                $0.50
              </Typography>

              <Typography className={classes.headerValue}>
                {data.coin_50}
              </Typography>
            </Container>
            <Container className={classes.sideTextContainer}>
              <Typography className={classes.header}>$0.05</Typography>
              <Typography className={classes.headerValue}>
                {data.coin_05}
              </Typography>
              <Typography className={(classes.header, classes.sHeader)}>
                $0.25
              </Typography>
              <Typography className={classes.headerValue}>
                {data.coin_25}
              </Typography>

              <Typography className={(classes.header, classes.sHeader)}>
                $1
              </Typography>
              <Typography className={classes.headerValue}>
                {data.coin_1}
              </Typography>
            </Container>
          </AccordionDetails>
        </Accordion>
      </Container>
    </Box>
  );
};
